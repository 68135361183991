import React, { useCallback, useContext } from 'react';
import { v4 as uuidV4 } from 'uuid';
import moment from 'moment';
import { useMutation, useQuery } from '@apollo/client';
import { emojiIndex } from 'emoji-mart';
import { SEND_MESSAGE } from '@schemas/mutations/chat';
import { GET_USER_INFO_BY_FIELD } from '@schemas/queries/users';
import { MESSAGES } from '@schemas/queries/chat';
import { AppContext } from '@utils/context/appContext';
import { toast } from 'react-toastify';
import MyToast from '@components/MyToast';
import { defaultToastOption } from '@utils/configs/Toast';
import { resetQueries } from '@utils/helper';

export const useSendMessage = ({
  conversationId,
  setContent,
  attachments,
  setAttachments,
  inputRef,
  textAreaRef,
}) => {
  const { isLogin } = useContext(AppContext);
  const [sendMessage, { client }] = useMutation(SEND_MESSAGE);
  const { data: getMeData } = useQuery(GET_USER_INFO_BY_FIELD(), {
    skip: !isLogin,
  });

  const nextAttachments = attachments?.map(({ fileId }) => ({
    type: 'media',
    attachmentId: fileId,
    url: null,
  }));

  const onSend = useCallback(
    async (textToSend, tempId, nextAttachments) => {
      await sendMessage({
        variables: {
          recipient: {
            conversationId: conversationId,
            recipientType: 'conversation',
          },
          message: {
            text: textToSend.trim(),
            attachments: nextAttachments || [],
          },
        },
        update: (cache, mutationResult) => {
          const existedData = cache.readQuery({
            query: MESSAGES,
            variables: {
              offset: 0,
              conversationId,
            },
          });
          if (existedData) {
            const newItemArray = [...existedData.messages.items],
              foundItemIndex = newItemArray.findIndex((e) => e.id === tempId);
            if (foundItemIndex >= 0) {
              newItemArray[foundItemIndex] =
                mutationResult.data.sendMessage.payload.lastMessage;
              cache.writeQuery({
                query: MESSAGES,
                variables: {
                  offset: 0,
                  conversationId,
                },
                data: {
                  ...existedData,
                  messages: {
                    ...(existedData.messages || []),
                    items: newItemArray,
                  },
                },
              });
            }
          }
        },
      });
    },
    [conversationId, sendMessage],
  );

  const onSendMessage = useCallback(
    async ({ nativeEvent }) => {
      const textToSend = replaceTextWithEmoji(nativeEvent.text, true);
      setTimeout(() => {
        const chatBox = document.getElementById('chat-box') || {};
        chatBox.scrollTop = chatBox?.scrollHeight;
      }, [500]);

      const tempId = uuidV4();

      const data = client.cache.readQuery({
        query: MESSAGES,
        variables: {
          offset: 0,
          conversationId,
        },
      });

      try {
        setContent('');
        setAttachments.clear();
        setTimeout(() => {
          inputRef && inputRef.current?.focus();
          textAreaRef && textAreaRef?.current?.setHeight();
        }, 300);
        if (data) {
          client.cache.writeQuery({
            query: MESSAGES,
            variables: {
              offset: 0,
              conversationId,
            },
            data: {
              ...data,
              messages: {
                ...data?.messages,
                items: [
                  {
                    id: tempId,
                    text: textToSend,
                    from: getMeData?.user?.id,
                    to: null,
                    type: 'update',
                    attachments: nextAttachments || [],
                    seenBy: [],
                    createdAt: moment().format(),
                    updatedAt: moment().format(),
                    callPayload: null,
                    loading: true,
                    error: false,
                  },
                  ...data.messages.items,
                ],
              },
            },
          });
        }
        await onSend(textToSend, tempId, nextAttachments);
        await resetQueries(['conversations'], client.cache);
      } catch (e) {
        await toast(
          <MyToast type="error" message={e?.message} />,
          defaultToastOption,
        );
        client.cache.writeQuery({
          query: MESSAGES,
          variables: {
            offset: 0,
            conversationId,
          },
          data: {
            ...data,
            messages: {
              ...data.messages,
              items: [
                {
                  id: tempId,
                  text: textToSend,
                  from: getMeData?.user?.id,
                  to: null,
                  type: 'update',
                  attachments: nextAttachments || [],
                  seenBy: [],
                  createdAt: moment().format(),
                  updatedAt: moment().format(),
                  callPayload: null,
                  loading: false,
                  error: true,
                },
                ...data.messages.items,
              ],
            },
          },
        });
      }
    },
    [
      client,
      conversationId,
      setContent,
      setAttachments,
      onSend,
      nextAttachments,
      inputRef,
      getMeData,
      textAreaRef,
    ],
  );

  const onTrySendMessage = useCallback(
    async ({ id, textToSend, attachments }) => {
      setTimeout(() => {
        const chatBox = document.getElementById('chat-box');
        chatBox.scrollTop = chatBox.scrollHeight;
      }, [500]);
      try {
        await onSend(textToSend, id, attachments);
      } catch (e) {
        console.log(e);
        const data = client.cache.readQuery({
          query: MESSAGES,
          variables: {
            offset: 0,
            conversationId,
          },
        });
        client.cache.writeQuery({
          query: MESSAGES,
          variables: {
            conversationId,
            offset: 0,
          },
          data: {
            ...data,
            messages: {
              ...data.messages,
              items: [
                {
                  id: id,
                  text: textToSend,
                  from: getMeData?.user?.id,
                  to: null,
                  type: 'update',
                  attachments: nextAttachments || [],
                  seenBy: [],
                  createdAt: moment().format(),
                  updatedAt: moment().format(),
                  callPayload: null,
                  loading: false,
                  error: true,
                },
                ...data.messages.items,
              ],
            },
          },
        });
      }
    },
    [client, conversationId, getMeData, nextAttachments, onSend],
  );

  const onTyping = useCallback(
    async (typing) => {
      try {
        await sendMessage({
          variables: {
            recipient: {
              conversationId: conversationId,
              recipientType: 'conversation',
            },
            senderAction: typing,
          },
        });
      } catch (e) {
        console.log(e);
      }
    },
    [conversationId, sendMessage],
  );

  return { onSendMessage, onTrySendMessage, onTyping };
};

export function replaceTextWithEmoji(text, shouldCheckFinalWord) {
  const split = text.split(' ');
  return split
    .map((word, index) => {
      if (word.length > 1 && word.includes(':')) {
        if (!shouldCheckFinalWord && index === split.length - 1) {
          return word;
        }
        const search = emojiIndex.search(word);
        return search.length > 0 ? search[0].native : word;
      }
      return word;
    })
    .join(' ');
}
