import { useMutation } from '@apollo/client';
import { HIDE_MESSAGE, REMOVE_MESSAGE } from '@schemas/mutations/chat';
import React, { useCallback } from 'react';
import { resetQueries } from '@utils/helper';
import styles from '@styles/components/Chat.module.css';
type Props = {
  close?: Function,
  context?: {
    messageId?: string,
  },
};

export default function MoreActionMessageDropdown(props: Props) {
  const {
    context: { messageId },
    close,
  } = props;
  const [hideMessage, { client }] = useMutation(HIDE_MESSAGE);
  const [removeMessage] = useMutation(REMOVE_MESSAGE);
  const onHideMessage = useCallback(async () => {
    try {
      close();
      await hideMessage({
        variables: {
          messageId,
        },
      });
      resetQueries(['conversations', 'messages'], client.cache);
    } catch (e) {
      console.log(e);
    }
  }, [messageId]);
  const onRemoveMessage = useCallback(async () => {
    try {
      close();
      await removeMessage({
        variables: {
          messageId,
        },
      });
      resetQueries(['conversations', 'messages'], client.cache);
    } catch (e) {
      console.log(e);
    }
  }, [messageId]);
  return (
    <div
      style={{ boxShadow: '-1px 0 5px 0 rgba(0, 0, 0, 0.12)' }}
      className="flex items-center justify-center rounded-rd4px bg-white overflow-hidden">
      <div className="flex-1">
        <div onClick={onHideMessage} className={styles.itemMessageMoreAction}>
          Xóa tin nhắn của chính bạn
        </div>
        <div onClick={onRemoveMessage} className={styles.itemMessageMoreAction}>
          Thu hồi tin nhắn
        </div>
      </div>
    </div>
  );
}
