import { getAvatarUrl } from '@utils/helper';
import { useQuery } from '@apollo/client';
import { READ_MEDIA_URLS } from '@schemas/mutations/image';

type Props = {
  avatarUrl?: string,
  avatarId?: string,
  gender?: string,
  type?: string,
  wrapperClass?: string,
};
export default function Avatar({
  avatarUrl,
  wrapperClass,
  type,
  avatarId,
}: Props) {
  const { data } = useQuery(READ_MEDIA_URLS, {
    variables: {
      fileId: avatarId,
    },
    skip: avatarUrl?.length || !avatarId,
  });

  const finalAvatarUrl = avatarUrl || data?.readMediaUrls.originalUrl;

  const nonAvatar = !finalAvatarUrl?.length;
  const defaultAvatar = getAvatarUrl({ type });

  return (
    <div
      className={`rounded-rd50 ${
        nonAvatar ? 'bg-contain' : 'bg-cover'
      } bg-no-repeat bg-center self-center ${wrapperClass} ${
        nonAvatar && 'border border-gray-400'
      }`}
      style={{ backgroundImage: `url("${finalAvatarUrl}")` }}>
      {nonAvatar && (
        <img
          src={defaultAvatar}
          className="w-full h-full rounded-rd50"
          alt="avt"
        />
      )}
    </div>
  );
}
