import '@styles/globals.scss';
import '@styles/components/Typography.module.scss.css';
import React, { Fragment, useState } from 'react';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import NextNprogress from 'nextjs-progressbar';
import useToggle from 'react-use/lib/useToggle';
import { AppContext } from '@utils/context/appContext';
import { ChatContext } from '@utils/context/chatContext';
const CustomApolloProvider = dynamic(() =>
  import('@components/CustomApolloProvider'),
);

const ModalWrapper = dynamic(() => import('@components/ModalWrapper'), {
  ssr: false,
});
const NotificationSuccess = dynamic(() =>
  import('@components/NotificationSuccess'),
);
import { ToastContainer, Slide } from 'react-toastify';
import DrawerWrapper from '@components/DrawerWrapper';
import ChatMobile from '@components/ChatMobile';
import { DefaultSeo } from 'next-seo';
import SEO from '../next-seo.config';

type Props = {
  Component: any,
  pageProps: Object,
};

function App({ Component, pageProps }: Props) {
  const getLayout = Component.getLayout ?? ((page) => page);
  const Layout = Component.layout ?? Fragment;

  const [isLogin, setIsLogin] = useToggle(null),
    [myId, setMyId] = useState('');

  const [conversationId, setConversationId] = useState(null),
    [participants, setParticipants] = useState(null),
    [showChat, setShowChat] = useState(false),
    [showChatMobile, setShowChatMobile] = useState(false),
    [showInnerChatMobile, setShowInnerChatMobile] = useState(false),
    [showRenameInput, setShowRenameInput] = useState(false),
    [showSearch, setShowSearch] = useState(false),
    [dataSearch, setDataSearch] = useState(null),
    [showDataSearch, setShowDataSearch] = useState(false);
  const [showSaveSuccess, setShowSaveSuccess] = useState(false);
  return (
    <CustomApolloProvider
      setIsLogin={setIsLogin}
      setMyId={setMyId}
      pageProps={pageProps}>
      <AppContext.Provider
        value={{
          isLogin,
          setIsLogin,
          myId,
          setMyId,
          setShowSaveSuccess,
        }}>
        <ChatContext.Provider
          value={{
            conversationId,
            setConversationId,
            participants,
            setParticipants,
            showChat,
            setShowChat,
            showRenameInput,
            setShowRenameInput,
            showSearch,
            setShowSearch,
            dataSearch,
            setDataSearch,
            showChatMobile,
            setShowChatMobile,
            showInnerChatMobile,
            setShowInnerChatMobile,
            showDataSearch,
            setShowDataSearch,
          }}>
          <Head>
            <title>RealTech247</title>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
            />
          </Head>
          <DefaultSeo {...SEO} />
          <Layout>
            {getLayout(<Component {...pageProps} />)}
            <ToastContainer transition={Slide} />
          </Layout>
          <NextNprogress
            color={'#2459ad'}
            startPosition={0.5}
            stopDelayMs={200}
            height={5}
          />
          {showChatMobile && (
            <DrawerWrapper
              open={showChatMobile}
              onClose={() => setShowChatMobile(false)}
              className="w-full h-screen"
              customTopAnchorBottom>
              <ChatMobile onToggleDrawer={setShowChatMobile} />
            </DrawerWrapper>
          )}
          {showSaveSuccess && (
            <ModalWrapper
              className="rounded-rd8px max-w-95%"
              open={showSaveSuccess}
              onClose={() => setShowSaveSuccess(false)}>
              <NotificationSuccess
                close={() => setShowSaveSuccess(false)}
                title="Lưu tin thành công"
              />
            </ModalWrapper>
          )}
        </ChatContext.Provider>
      </AppContext.Provider>
    </CustomApolloProvider>
  );
}

export default App;
