import React, { useContext, useRef, useState } from 'react';
import { TippyProps } from '@tippyjs/react/headless';
import { sticky } from 'tippy.js';
import useMeasure from 'react-use/lib/useMeasure';
import { useSpring, animated } from 'react-spring';
import LazyTippy from './lazyTippy';
import { useRouter } from 'next/router';

const DropdownContext = React.createContext({
  parentRef: null,
});

export type DropdownProps = {
  DropdownComponent?: React.Component,
  dropdownContext?: Object,
  dropdownWrapperStyle?: string,
  dropdownSelectionStyle?: string,
  tippyProps?: TippyProps,
  onToggle?: Function,
  show?: boolean,
  children?: React.ReactChildren,
  style?: string,
  disabled?: boolean,
  appendTo?: any,
  backToFirstPage?: Boolean,
};

const config = { duration: 150 };

export default function Dropdown(props: DropdownProps) {
  const initialStyles = { opacity: 0 };
  const [animationStyle, setSpring] = useSpring(() => initialStyles),
    [show, setShow] = useState(props.show),
    triggerRef = useRef(null),
    { parentRef } = useContext(DropdownContext);

  function onMount() {
    setSpring({
      opacity: 1,
      onRest: () => {},
      config,
    });
  }

  function onHide({ unmount }) {
    setSpring({
      ...initialStyles,
      onRest: unmount,
      config: { ...config, clamp: true },
    });
  }

  const [dropdownMeasureRef, { width }] = useMeasure(),
    {
      children,
      DropdownComponent,
      dropdownContext,
      dropdownWrapperStyle,
      tippyProps = {},
      onToggle,
      dropdownSelectionStyle,
      style,
      disabled,
      appendTo,
      backToFirstPage,
      ...viewProps
    } = props;

  const defaultModifiers = [
    {
      name: 'preventOverflow',
      options: {
        padding: 0,
        // altBoundary: document?.body,
        altAxis: false,
        tether: false,
      },
    },
  ];
  if (!process.browser) {
    return null;
  }
  const router = useRouter();
  return (
    <LazyTippy
      disabled={disabled}
      animation
      maxWidth="none"
      interactive
      inlinePositioning
      sticky
      appendTo={appendTo || parentRef || document.getElementById('modal-root')}
      trigger="click"
      plugins={[sticky]}
      hideOnClick
      onShow={() => {
        onToggle && onToggle(true);
        setShow(true);
        if (backToFirstPage) {
          router.push(router.query);
        }
      }}
      onMount={onMount}
      onHide={(instance) => {
        onToggle && onToggle(false);
        onHide(instance);
        setShow(false);
      }}
      placement="bottom"
      moveTransition="transform 0.2s ease-out"
      popperOptions={{
        modifiers: defaultModifiers,
      }}
      {...tippyProps}
      reference={triggerRef}
      render={(attrs, content, popper) => {
        return (
          <animated.div
            {...attrs}
            style={{ minWidth: width, ...animationStyle }}>
            <DropdownInner
              dropdownWrapperStyle={dropdownWrapperStyle}
              popper={popper}
              dropdownSelectionStyle={dropdownSelectionStyle}
              onToggle={onToggle}
              dropdownContext={dropdownContext}
              DropdownComponent={DropdownComponent}
            />
          </animated.div>
        );
      }}>
      <div
        {...viewProps}
        ref={triggerRef}
        className={`w-full cursor-pointer relative ${style}`}>
        {typeof children === 'function' ? children({ show }) : children}
        <div
          ref={dropdownMeasureRef}
          style={{
            position: 'absolute',
            opacity: 0,
            pointerEvents: 'none',
            width: '100%',
          }}
        />
      </div>
    </LazyTippy>
  );
}

type DropdownInnerProps = {
  popper?: Function,
  dropdownWrapperStyle?: string,
  DropdownComponent?: React.Component,
  onToggle?: Function,
  dropdownContext?: Object,
  dropdownSelectionStyle?: string,
};

function DropdownInner({
  popper,
  dropdownWrapperStyle,
  DropdownComponent,
  onToggle,
  dropdownContext,
  dropdownSelectionStyle,
}: DropdownInnerProps) {
  const [parentRef, setParentRef] = useState(null);
  return (
    <DropdownContext.Provider value={{ parentRef }}>
      <div ref={setParentRef} className={dropdownWrapperStyle}>
        <DropdownComponent
          close={() => {
            popper.hide();
            onToggle && onToggle(false);
          }}
          dropdownSelectionStyle={dropdownSelectionStyle}
          popper={popper}
          context={{
            ...dropdownContext,
          }}
        />
      </div>
    </DropdownContext.Provider>
  );
}
