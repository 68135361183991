import { useQuery } from '@apollo/client';
import { useEffect } from 'react';
import moment from 'moment';
import { CONVERSATIONS } from '@schemas/queries/chat';
import { CONVERSATION_UPDATED } from '@schemas/subcriptions/chat';
import pickBy from 'lodash/pickBy';

export const useGetConversation = ({ search }) => {
  const { data, subscribeToMore, loading } = useQuery(CONVERSATIONS, {
    variables: pickBy(
      {
        limit: 10,
        search,
      },
      (e) => e,
    ),
  });
  useEffect(() => {
    subscribeToMore({
      document: CONVERSATION_UPDATED,
      updateQuery: (prev, { subscriptionData }) => {
        if (!prev || !subscriptionData.data) {
          return prev;
        }
        const { conversationUpdated } = subscriptionData.data,
          { lastMessage, id: conversationId } = conversationUpdated;
        const {
            conversations: { items },
          } = prev,
          existedConversation = items.find((e) => e.id === conversationId);
        let newItemArray = [...items];
        if (
          lastMessage &&
          moment().diff(moment(lastMessage.createdAt), 'second') < 1
        ) {
          if (existedConversation) {
            newItemArray = newItemArray.filter(
              (e) => e.id !== existedConversation.id,
            );
          }
          newItemArray.unshift(conversationUpdated);
          return {
            ...prev,
            conversations: {
              ...prev.conversations,
              items: newItemArray,
            },
          };
        }
        return prev;
      },
    });
  }, [subscribeToMore]);
  return { data, loading };
};
