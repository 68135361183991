import styles from '@styles/components/Chat.module.css';
import Avatar from '@components/Menu/components/Avatar';
import { useQuery } from '@apollo/client';
import { GET_USER_INFO_BY_FIELD } from '@schemas/queries/users';
import React from 'react';
import moment from 'moment/moment';
import { useGetTimeSince } from '@utils/hooks/useTimeSince';
import MoreActionDrawer from '@components/ChatMobile/components/MoreActionDrawer';
import { useGetUserBasicInfo } from '@utils/hooks/Chat/useGetUserBasicInfo';
import ActiveFunction from '@components/Chat/components/activeFunction';
type ConversationProps = {
  conversationId?: string,
  participants?: Array,
  lastMessage?: Object,
  pinnedAt?: string,
  blockedBy?: boolean,
  onPress?: Function,
};

export default function PeopleMobile(props: ConversationProps) {
  const {
    conversationId,
    participants,
    lastMessage,
    pinnedAt,
    blockedBy,
    onPress,
    ...rest
  } = props;
  const { data: myData } = useQuery(GET_USER_INFO_BY_FIELD());
  const myId = myData?.user?.id;

  const userId = participants?.find((e) => e.userId !== myId)?.userId;
  const nickname = participants?.find(
    (e) => e.nickname !== null && e.userId !== myId,
  )?.nickname;
  const { data } = useGetUserBasicInfo({ userId });

  const fullName = data?.getUserBasicInfo?.fullName || '',
    avatar = data?.getUserBasicInfo?.avatar?.originalUrl;

  const notSeen = !lastMessage.seenBy?.includes(myId);

  const shortMessage =
    lastMessage?.attachments?.length > 0
      ? ['[Tệp đính kèm]']
      : lastMessage?.text;
  const renderTimeSince = useGetTimeSince(moment(lastMessage?.createdAt));
  const lastOnline = moment(data?.getUserBasicInfo?.lastOnline);
  return (
    <div {...rest} className={`${styles.conversation} flex-1`}>
      <button onClick={onPress} className="relative">
        <Avatar
          avatarUrl={avatar}
          wrapperClass={`${styles.avatar} border border-blue-main`}
        />
        {blockedBy?.includes(myId) && (
          <div
            style={{
              position: 'absolute',
              zIndex: 1000,
              top: 0,
              left: 0,
              width: 12,
              height: 12,
              borderRadius: '50%',
              backgroundColor: 'white',
            }}>
            <img alt="block" src="/svg/blockIcon.svg" className="flex-1" />
          </div>
        )}
        <ActiveFunction lastOnline={lastOnline} />
      </button>
      <div className="flex w-full flex-col m-2 items-start justify-start space-y-1 table table-fixed bg-white">
        <div className="flex flex-row w-full justify-between">
          <div onClick={onPress} className="truncate w-full boldBodyText">
            {nickname || fullName}
          </div>
          <MoreActionDrawer
            conversationId={conversationId}
            userId={userId}
            myId={myId}
            blockedBy={blockedBy}
          />
        </div>
        <div className="flex flex-row justify-between w-full bg-red-4">
          <p
            className={`text-11 font-text truncate ${
              notSeen && 'font-bold font-textBold'
            }`}>
            {shortMessage}
          </p>
          <p
            className={`text-11 font-text block whitespace-nowrap ml-24px ${
              notSeen && 'font-bold font-textBold'
            }`}>
            {renderTimeSince}
          </p>
        </div>
      </div>
      {pinnedAt?.length && (
        <img
          style={{ right: 40 }}
          alt="pin"
          src="/svg/pin.svg"
          className="absolute top-0 "
        />
      )}
    </div>
  );
}
