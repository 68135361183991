import React, { useRef, useState } from 'react';
import { useGetConversation } from '@hooks/Chat/useGetConversation';
import useDebounce from 'react-use/lib/useDebounce';
import RenderConversations from '@components/ChatMobile/components/RenderConversations';
import { useQuery } from '@apollo/client';
import { GET_USER_INFO_BY_FIELD } from '@schemas/queries/users';
import RenderContentChat from '@components/ChatMobile/components/RenderContentChat';
import { useChatContext } from '@utils/context/chatContext';

type ChatMobileProps = {
  onToggleDrawer?: Function,
};
export default function ChatMobile({ onToggleDrawer }: ChatMobileProps) {
  const {
    conversationId,
    participants,
    showInnerChatMobile,
    setShowInnerChatMobile,
  } = useChatContext();
  const textAreaRef = useRef(null);
  const [searchValue, setSearchValue] = useState(''),
    [debounceValue, setDebounceValue] = useState('');
  const { data: myData } = useQuery(GET_USER_INFO_BY_FIELD());
  const myId = myData?.user?.id;

  const { data, loading } = useGetConversation({ search: debounceValue || '' });

  useDebounce(
    () => {
      setDebounceValue(searchValue);
    },
    300,
    [searchValue],
  );
  const arr = new Array(12).fill('loading');
  const listConservations = loading ? arr : data?.conversations.items || [];
  return (
    <div className="w-full h-full">
      {!showInnerChatMobile && (
        <div
          className="w-full flex flex-col border-b pr-3 py-1 bg-white h-50px sticky top-0 bg-white"
          style={{
            borderColor: '#e2e2e2',
            zIndex: 10000,
          }}>
          <div className="flex flex-1 items-center justify-center">
            <button onClick={() => onToggleDrawer(false)} className="w-48px ">
              <img alt="down" src="/svg/rectangleDownIcon.svg" />
            </button>
            <div className="flex flex-1 justify-center items-center px-2 text-base font-bold">
              <input
                style={{ fontSize: 14 }}
                onChange={(e) => setSearchValue(e.target.value)}
                value={searchValue}
                className="flex-1 px-1.5 py-1 rounded-rd4px border border-blue-main"
                placeholder="Tìm kiếm theo từ khóa"
              />
            </div>
          </div>
        </div>
      )}
      {!showInnerChatMobile && (
        <RenderConversations
          loading={loading}
          listConservations={listConservations}
        />
      )}
      {showInnerChatMobile && conversationId && (
        <RenderContentChat
          participants={participants}
          conversationId={conversationId}
          textAreaRef={textAreaRef}
          setShowChat={setShowInnerChatMobile}
          myId={myId}
        />
      )}
    </div>
  );
}
