import QRCode from 'qrcode.react';

type Props = {
  value: string,
  size?: number,
  className?: string,
};

export default function MyQRCode(props: Props) {
  const { value = '', size = 146, className, ...rest } = props;
  return (
    <QRCode
      {...rest}
      className={className}
      value={value}
      level="H"
      size={size}
      id="my-qr-code"
    />
  );
}
