import React from 'react';
import tinycolor from 'tinycolor2';
import useMeasure from 'react-use/lib/useMeasure';

type Props = {
  message?: string | Function,
  type: 'success' | 'error' | 'warning' | 'notification',
  wrapperStyle?: string,
};

export default function MyToast(props: Props) {
  const { message, type = 'success', wrapperStyle } = props,
    renderMessage =
      typeof message === 'function' ? (
        message()
      ) : (
        <p className="bodyText">{message}</p>
      );
  let icon,
    color = '';
  switch (type) {
    case 'success':
      icon = 'success.svg';
      color = '#40d124';
      break;
    case 'error':
      icon = 'infoRed.svg';
      color = '#ff0000';
      break;
    case 'warning':
      icon = 'warning.svg';
      color = '#ffcc00';
      break;
  }

  const [measureRef, { height }] = useMeasure();

  return (
    <div className="w-full flex justify-center">
      <div
        ref={measureRef}
        className={`flex items-center bg-white rounded-rd4px border border-gray-300 ${wrapperStyle}`}
        style={{ minHeight: 69, width: '100%' }}>
        <div
          className={`w-4px`}
          style={{ backgroundColor: color, height, border: '4px 0 0 4px' }}
        />
        <div
          className="flex flex-1 items-center relative"
          style={{
            minHeight: height,
            backgroundColor: tinycolor(color).setAlpha(0.2),
          }}>
          <img
            alt="icon"
            className="w-24px h-24px mx-8px"
            src={`/svg/${icon}`}
          />
          <div className="flex flex-col pr-8px items-center">
            {renderMessage}
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            className="absolute top-6 right-1 h-5 w-5 text-gray-400 hover:text-gray-500"
            viewBox="0 0 24 24"
            stroke="currentColor">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>
      </div>
    </div>
  );
}
