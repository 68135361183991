import styles from '@styles/components/Mybutton.module.scss';

type Props = {
  label?: string,
  type?: 'primary' | 'secondary' | 'secondaryAdd' | 'error' | 'red',
  onPress?: Function,
  wrapperClass?: string,
  disabled?: boolean,
  style?: Object,
  leftComponent?: Function,
  rightComponent?: Function,
  labelStyle?: string,
};

export default function MyButton(props: Props) {
  const {
    label,
    type = 'primary',
    onPress,
    wrapperClass,
    disabled,
    style,
    leftComponent,
    rightComponent,
    labelStyle,
    ...rest
  } = props;

  const buttonType = `${type}Button`;
  const labelType = `${type}Label`;

  return (
    <button
      {...rest}
      onClick={onPress}
      disabled={disabled}
      style={style}
      className={`${styles.buttonContainer} ${
        styles[buttonType]
      } ${wrapperClass} ${disabled && 'opacity-50 cursor-not-allowed'}`}>
      {leftComponent && leftComponent()}
      <div className={`${styles[labelType]} ${labelStyle} whitespace-nowrap`}>
        {label}
      </div>
      {rightComponent && rightComponent()}
    </button>
  );
}
