import {
  realEstateType,
  realEstateProjectType,
} from '@utils/configs/RealEstateConfiguarations/realEstate';

function getConfigs({ configs, typeOfDemand, postType }) {
  return configs.map((realEstateType) => ({
    realEstateType,
    typeOfDemand,
    postType,
  }));
}

export const allPostType = 'allPostType',
  allRealEstateType = 'allRealEstateType',
  allProjectType = 'allProjectType';

const valueRealEstateType = Object.entries(realEstateType).map(
  ([, value]) => value,
);

const valueProjectType = Object.entries(realEstateProjectType).map(
  ([, value]) => value,
);

export const types = [
  ...getConfigs({
    configs: valueRealEstateType.filter((type) => type !== 'motel'),
    typeOfDemand: 'ForSale',
    postType: allPostType,
  }),
  ...getConfigs({
    configs: valueRealEstateType,
    typeOfDemand: 'ForRent',
    postType: allPostType,
  }),
  {
    realEstateType: allRealEstateType,
    typeOfDemand: 'ForSale',
    postType: allPostType,
  },
  {
    realEstateType: allRealEstateType,
    typeOfDemand: 'ForRent',
    postType: allPostType,
  },
  {
    realEstateType: allRealEstateType,
    typeOfDemand: 'ForSale',
    postType: '1',
  },
  {
    realEstateType: allRealEstateType,
    typeOfDemand: 'ForSale',
    postType: '2',
  },
  ...getConfigs({
    configs: valueProjectType,
    typeOfDemand: 'project',
    postType: allPostType,
  }),
  {
    realEstateType: allProjectType,
    typeOfDemand: 'project',
    postType: allPostType,
  },
  {
    realEstateType: allRealEstateType,
    typeOfDemand: 'ToBuy',
    postType: allPostType,
  },
  {
    realEstateType: allRealEstateType,
    typeOfDemand: 'ToRent',
    postType: allPostType,
  },
  ...getConfigs({
    configs: valueRealEstateType.filter((type) => type !== 'motel'),
    typeOfDemand: 'ToBuy',
    postType: allPostType,
  }),
  ...getConfigs({
    configs: valueRealEstateType,
    typeOfDemand: 'ToRent',
    postType: allPostType,
  }),
];
