import Icon from '@public/svg/empty_box.svg';

export function EmptyConversation() {
  return (
    <div className="flex flex-col w-full h-full items-center justify-center px-6 space-y-4">
      <div className="w-260px h-260px">
        <Icon className="w-full h-full" />
      </div>
      <div className="text-base text-gray-400 text-center">
        Không có cuộc hội thoại nào trong hòm thư của bạn
      </div>
    </div>
  );
}
