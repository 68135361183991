import { useQuery } from '@apollo/client';
import { GET_USER_BASIC_INFO } from '@schemas/queries/users';

export const useGetUserBasicInfo = ({ userId }) => {
  const { data } = useQuery(GET_USER_BASIC_INFO, {
    variables: { userId },
    skip: !userId,
  });
  return { data };
};
