import MediaComponent from '@components/pages/PublishRealEstate/components/MediaComponent';
import styles from '@styles/components/Chat.module.css';
import React from 'react';

type Props = {
  files?: Array,
  setFiles?: Function,
};

export default function ImageFile({ files, setFiles }: Props) {
  return (
    <div className="grid-cols-5 grid gap-12px w-full">
      {files?.map((item, index) => {
        return (
          <MediaComponent
            wrapperStyle={styles.image}
            {...item}
            remove={() => setFiles.removeAt(index)}
            key={item.fileId}
            // readOnly={readOnly}
          />
        );
      })}
    </div>
  );
}
