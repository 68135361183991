import { useCallback } from 'react';
import { resetQueries } from '@utils/helper';
import { useMutation } from '@apollo/client';
import { SET_NICK_NAME } from '@schemas/mutations/chat';

export const useSetNickname = ({
  conversationId,
  name,
  setName,
  setParticipants,
  setShowRenameInput,
  setUserInput,
}) => {
  const [setNickName, { client, loading }] = useMutation(SET_NICK_NAME);
  const onSetNickName = useCallback(async () => {
    try {
      const { data } = await setNickName({
        variables: {
          conversationId,
          nickname: name,
        },
      });
      setParticipants(data?.setNickname?.payload?.participants);
      setShowRenameInput(false);
      setName('');
      setUserInput(false);
      resetQueries(['conversations', 'messages'], client.cache);
    } catch (e) {
      console.log(e);
    }
  }, [setNickName, name, conversationId, setShowRenameInput, setName]);
  return { onSetNickName, loading };
};
