import gql from 'graphql-tag';

export const MESSAGE_ALL_FIELDS = gql`
  fragment MessageAllFields on Conversation {
    id
    name
    participants {
      userId
      nickname
    }
    pinnedAt
    createdAt
    updatedAt
    blockedBy
    lastMessage {
      id
      from
      to
      text
      type
      attachments {
        type
        attachmentId
      }
      seenBy
      loading @client
      createdAt
      updatedAt
    }
    typing
    updatedAt
  }
`;

export const FILE_ALL_FIELDS = gql`
  fragment FileAllFields on FileWithFullUrls {
    __typename
    ... on FileWithFullUrls {
      fileId
      originalUrl
      type
      urls {
        type
        url
      }
    }
  }
`;

export const MEDIA_UNION = gql`
  fragment FileAllFields2 on MediaUnionWithFullUrls {
    ... on YoutubeFile {
      type
      fileId
      youtubeMetadata {
        thumbnailUrl
        videoId
      }
    }
    ... on FileWithFullUrls {
      type
      fileId
      originalUrl
      urls {
        type
        url
      }
    }
  }
`;

export const NEED_ALL_FIELDS = gql`
  fragment NeedAllFields on RealEstateParameter {
    area {
      key
      value
      from
      to
    }
    width {
      key
      value
      from
      to
    }
    length {
      key
      value
      from
      to
    }
    priceToBuy {
      key
      value
      from
      unit
      to
    }
    priceToRent {
      key
      value
      from
      unit
      to
    }
    priceToRentByDay {
      key
      value
      from
      unit
      to
    }
    priceToRentByMonth {
      key
      value
      from
      unit
      to
    }
    bedroomArea {
      key
      value
      from
      to
      unit
    }
    buildingType {
      key
      value
      from
      to
    }
    floorCount {
      key
      value
      from
      to
    }
    floorPosition {
      key
      value
      from
      to
    }
    roomCount {
      key
      value
      from
      to
    }
    bedroomCount {
      key
      value
      from
      to
    }
    toiletCount {
      key
      value
      from
      to
    }
    villaView {
      key
      value
      from
      to
    }
    hotelStar {
      key
      value
      from
      to
    }
    timeToRent {
      key
      value
      from
      to
    }
    timeForRentUnits {
      key
      value
      from
      to
    }
    alleyWidth {
      key
      value
      from
      to
    }
    blockCount {
      key
      value
      from
      to
    }
  }
`;

export const INFORMATION_EMPLOYEE_FIELDS = gql`
  fragment informationEmployeeFields on B2BUserType {
    id
    fullName
    employeeCode
    email
    avatar {
      __typename
      ... on FileWithFullUrls {
        fileId
        originalUrl
        type
        urls {
          type
          url
        }
      }
      ... on YoutubeFile {
        fileId
        type
        youtubeMetadata {
          title
          description
          thumbnailUrl
          videoId
        }
      }
    }
    gender
    phone
    secondPhone
    username
    province
    district
    company
    dateOfBirth
    creator {
      id
      fullName
    }
    isLocked
    role
    idNumber
    idImages {
      __typename
      ... on FileWithFullUrls {
        fileId
        originalUrl
        type
        urls {
          type
          url
        }
      }
      ... on YoutubeFile {
        fileId
        type
        youtubeMetadata {
          title
          description
          thumbnailUrl
          videoId
        }
      }
    }
    approveStatus {
      status
      reasonType
      reason
    }
    attachments {
      __typename
      ... on FileWithFullUrls {
        fileId
        originalUrl
        type
        urls {
          type
          url
        }
      }
      ... on YoutubeFile {
        fileId
        type
        youtubeMetadata {
          title
          description
          thumbnailUrl
          videoId
        }
      }
    }
    createdAt
    updatedAt
  }
`;
export const INFORMATION_USER_FIELDS = gql`
  fragment informationUserFields on B2BUserType {
    id
    fullName
    employeeCode
    email
    lastOnline
    avatar {
      __typename
      ... on FileWithFullUrls {
        fileId
        originalUrl
        type
        urls {
          type
          url
        }
      }
      ... on YoutubeFile {
        fileId
        type
        youtubeMetadata {
          title
          description
          thumbnailUrl
          videoId
        }
      }
    }
    gender
    phone
    secondPhone
    username
    province
    district
    company
    creator {
      id
      fullName
      avatar {
        __typename
        ... on FileWithFullUrls {
          fileId
          originalUrl
          type
          urls {
            type
            url
          }
        }
        ... on YoutubeFile {
          fileId
          type
          youtubeMetadata {
            title
            description
            thumbnailUrl
            videoId
          }
        }
      }
    }
    dateOfBirth
    birthDate
    isLocked
    role
    idNumber
    idImages {
      __typename
      ... on FileWithFullUrls {
        fileId
        originalUrl
        type
        urls {
          type
          url
        }
      }
      ... on YoutubeFile {
        fileId
        type
        youtubeMetadata {
          title
          description
          thumbnailUrl
          videoId
        }
      }
    }
    approveStatus {
      status
      reasonType
      reason
    }
    attachments {
      __typename
      ... on FileWithFullUrls {
        fileId
        originalUrl
        type
        urls {
          type
          url
        }
      }
      ... on YoutubeFile {
        fileId
        type
        youtubeMetadata {
          title
          description
          thumbnailUrl
          videoId
        }
      }
    }
    departments {
      id
      positionName
      positionType
      department {
        id
        name
        company
      }
      manager {
        id
        user {
          id
          fullName
          avatar {
            __typename
            ... on FileWithFullUrls {
              fileId
              originalUrl
              type
              urls {
                type
                url
              }
            }
            ... on YoutubeFile {
              fileId
              type
              youtubeMetadata {
                title
                description
                thumbnailUrl
                videoId
              }
            }
          }
        }
      }
      creator {
        id
        fullName
        avatar {
          __typename
          ... on FileWithFullUrls {
            fileId
            originalUrl
            type
            urls {
              type
              url
            }
          }
          ... on YoutubeFile {
            fileId
            type
            youtubeMetadata {
              title
              description
              thumbnailUrl
              videoId
            }
          }
        }
      }
      editor {
        id
        fullName
      }
      deletedBy {
        id
        fullName
      }
      createdAt
      updatedAt
    }
    productPermissions {
      id
      name
      configs
    }
    projectPermissions {
      id
      name
      configs
    }
    createdAt
    updatedAt
  }
`;

export const INFO_DEPARTMENT_FIELDS = gql`
  fragment infoDepartmentFields on DepartmentOutputType {
    id
    name
    permissions {
      name
      description
      group
      configs {
        name
        description
        value
      }
    }
  }
`;

export const NEEDED_REAL_ESTATE_ALL_FIELDS = gql`
  fragment NeededRealEstateAllFields on NeedRealEstateOutputType {
    demandEstate
    type
    realEstateId
    province
    district
    fullAddress
    isNeedToBuy
    isIncognito
    ward
    street
    timeForRent {
      key
      value
      from
      to
      unit
    }
    timeForRentUnit
    width {
      key
      value
      from
      to
      unit
    }
    length {
      key
      value
      from
      to
      unit
    }
    rear {
      key
      value
      from
      to
      unit
    }
    area {
      key
      value
      from
      to
      unit
    }
    floorCount {
      key
      value
      from
      to
      unit
    }
    buildingType
    villaView
    price {
      key
      value
      from
      to
      unit
    }
    priceUnit
    bedroomCount {
      key
      value
      from
      to
      unit
    }
    blockName
    floorPosition {
      key
      value
      from
      to
      unit
    }
    buildingType
    toiletCount {
      key
      value
      from
      to
      unit
    }
    direction
    projectName
    postTitle
    postContent
    privateNote
    contactName
    contactPhone1
    contactPhone2
    contactEmail
    postType
    startShowTime
    endShowTime
    tradingStatus
    postStatus
    creator {
      id
      avatar {
        ...FileAllFields
      }
    }
    #    map {
    #      lat
    #      long
    #    }
    fixedUtilities
    utilities
    localUtilities
    equipmentsAndServices
    investor
    industryTrade
    buildingType
    hasSeaView
    hotelStar
    latestRenewInfo {
      id
      startTime
      endTime
      status
      updatedAt
      createdAt
      postType
    }
    roomCount {
      key
      value
      from
      to
      unit
    }
    factoryFunctions
    hasMezzanine
    isSaved
    isNeedToBuy
    isNeedToRenew
    service
    company
    numberOfPostingDay
  }
  ${FILE_ALL_FIELDS}
`;

export const PROJECT_ALL_FIELDS_FRAGMENT = gql`
  fragment ProjectAllFields on RealEstateProject {
    id
    projectType
    isSharing
    investorName
    contactPhones
    contactEmail
    taxId
    projectStatus
    projectName
    projectCode
    province
    street
    district
    ward
    provinceName
    streetName
    districtName
    wardName
    houseNumber
    fullAddress
    map {
      coordinates
    }
    internalMap {
      coordinates
    }
    exclusiveSupplier
    exclusiveSupplierSub
    companyNote
    projectScale
    attachments
    fixedUtilities
    utilities
    localUtilities
    equipmentsAndServices
    legals
    legalMediaUrls
    mediaUrls
    mapPositionImages
    mapPositionDescription
    landImages
    landDescription
    projectArea {
      area
      unit
    }
    constructionProjectArea {
      area
      unit
    }
    projectSubType
    projectViews
    floorCountPerBlockRange {
      from
      to
      unit
    }
    standardStar
    townhouseCount
    apartmentCount
    independentVillaCount
    doubleVillaCount
    shopHouseCount
    penthouseCount
    condotelCount
    offictelCount
    commercialAreaCount
    typeAreas {
      area
      unit
    }
    totalLandArea {
      width
      length
      rear
      unit
    }
    totalZone
    totalBackground
    floorCanBuild
    directions
    priceRange {
      from
      to
      unit
    }
    totalWarehouse
    totalWorkshops
    floorRange {
      from
      to
      unit
    }
    totalAreaOfLand {
      area
      unit
    }
    totalAreaBuild {
      area
      unit
    }
    priceByGap {
      stageName
      price
      unitPrice
    }
    totalBlock

    totalFloorShopping
    totalAreaFloor {
      from
      to
      unit
    }
    totalBasements

    services {
      serviceName
      from
      to
    }
    apartment {
      priceByStages {
        typeArea {
          area
          unit
        }
        name
        priceByStage {
          from
          to
          unit
        }
      }
      bedroomCount {
        from
        to
        unit
      }
      bedroomArea {
        from
        to
        unit
      }
      toiletCount {
        from
        to
        unit
      }
      toiletArea {
        from
        to
        unit
      }
      views
    }
    townhouse {
      priceByStages {
        typeArea {
          area
          unit
        }
        name
        priceByStage {
          from
          to
          unit
        }
      }
      floorCount
      areaList {
        length
        width
        rear
        unit
      }
      views
    }
    independentVilla {
      priceByStages {
        typeArea {
          area
          unit
        }
        name
        priceByStage {
          from
          to
          unit
        }
      }
      bedroomCount {
        from
        to
        unit
      }
      bedroomArea {
        from
        to
        unit
      }
      floorCount {
        from
        to
        unit
      }
      areaList {
        length
        width
        rear
        unit
      }
      views
    }
    doubleVilla {
      priceByStages {
        typeArea {
          area
          unit
        }
        name
        priceByStage {
          from
          to
          unit
        }
      }
      bedroomCount {
        from
        to
        unit
      }
      bedroomArea {
        from
        to
        unit
      }
      floorCount {
        from
        to
        unit
      }
      areaList {
        length
        width
        rear
        unit
      }
      views
    }
    condotel {
      priceByStages {
        typeArea {
          area
          unit
        }
        name
        priceByStage {
          from
          to
          unit
        }
      }
      bedroomCount {
        from
        to
        unit
      }
      bedroomArea {
        from
        to
        unit
      }
      toiletCount {
        from
        to
        unit
      }
      toiletArea {
        from
        to
        unit
      }
      views
    }
    penthouse {
      priceByStages {
        typeArea {
          area
          unit
        }
        name
        priceByStage {
          from
          to
          unit
        }
      }
      bedroomCount {
        from
        to
        unit
      }
      bedroomArea {
        from
        to
        unit
      }
      toiletCount {
        from
        to
        unit
      }
      toiletArea {
        from
        to
        unit
      }
      views
    }
    shophouse {
      priceByStages {
        typeArea {
          area
          unit
        }
        name
        priceByStage {
          from
          to
          unit
        }
      }
      areaList {
        length
        width
        rear
        unit
      }
      toiletCount
      views
    }
    offictel {
      priceByStages {
        typeArea {
          area
          unit
        }
        name
        priceByStage {
          from
          to
          unit
        }
      }
      bedroomCount {
        from
        to
        unit
      }
      bedroomArea {
        from
        to
        unit
      }
      toiletCount {
        from
        to
        unit
      }
      toiletArea {
        from
        to
        unit
      }
      views
    }
    commercialArea {
      priceByStages {
        typeArea {
          area
          unit
        }
        name
        priceByStage {
          from
          to
          unit
        }
      }
      kioskCount
      commercialAreaCount
      views
    }
    totalCinema {
      from
      to
      unit
    }
    totalFoodTown {
      from
      to
      unit
    }
    creator
    editor
    deletedBy
    tags
    hasVideo
    hasImage
    has360Image
    company
    createdAt
    updatedAt
    deletedAt
    saleRequestStatus {
      id
      status
    }
  }
`;

export const REAL_ESTATE_ALL_FIELDS_V2_FRAGMENT = gql`
  fragment RealEstateAllFieldsV2 on RealEstate {
    id
    demandEstate
    realEstateId
    realEstateType
    type
    province {
      id
      name
    }
    district {
      id
      name
    }
    isForSell
    width
    length
    rear
    area
    floorCount
    bedRoomArea
    price
    priceUnit
    bedroomCount
    onFloor
    roomArea
    roomNumber
    toiletCount
    direction
    project
    postTitle
    privateNote
    legals
    mediaUrls {
      ...FileAllFields
    }
    postType
    startShowTime
    endShowTime
    tradingStatus
    postStatus
    isSaved
    isNeedToRenew
    latestRenewInfo {
      id
      startTime
      endTime
      status
      updatedAt
      createdAt
      postType
    }
    isAuthorizedRe
    isSaleOffRE
    creator {
      id
      type
      fullName
    }
    roomCount
    motelName
    createdAt
    saveItem {
      id
      belongsToCollection {
        id
      }
      note
    }
  }
  ${FILE_ALL_FIELDS}
`;

export const REAL_ESTATE_ALL_FIELDS_FRAGMENT = gql`
  fragment RealEstateAllFields on RealEstate {
    id
    realEstateType
    demandEstate
    realEstateId
    clearanceArea
    approvalReason
    type
    province {
      id
      name
    }
    district {
      id
      name
    }
    ward {
      id
      name
    }
    street {
      id
      name
    }
    fullAddress
    isForSell
    isIncognito
    houseNumber
    hasAlley
    alleyTurnCount
    timeForRent
    timeForRentUnit
    frontHomeAlleyWidth
    isNewConstruction
    width
    length
    rear
    area
    floorCount
    buildingType
    bedRoomArea
    villaView
    price
    tnccPrice
    priceUnit
    bedroomCount
    block
    onFloor
    motelName
    buildingType
    roomArea
    roomNumber
    toiletCount
    direction
    project
    postTitle
    postContent
    privateNote
    legals
    legalMediaUrls {
      ...FileAllFields
    }
    contactName
    contactPhones
    contactPhone1
    contactPhone2
    contactEmail
    mediaUrls {
      ...FileAllFields2
    }
    postType
    startShowTime
    endShowTime
    tradingStatus
    postStatus
    isSaved
    isNeedToRenew
    latestRenewInfo {
      id
      startTime
      endTime
      status
      updatedAt
      createdAt
      postType
    }
    isAuthorizedRe
    isSaleOffRE
    industryTrade
    creator {
      id
      type
      fullName
      gender
      avatar {
        ...FileAllFields
      }
    }
    map {
      type
      coordinates
    }
    fixedUtilities
    utilities
    localUtilities
    equipmentsAndServices
    investor
    shophouseField
    buildingType
    hasSeaView
    hotelStar
    roomCount
    warehouseType
    warehouseRoomCount
    hasMezzanine
    motelName
    createdAt
    updatedAt
    saveItem {
      id
      note
      belongsToCollection {
        id
      }
    }
    approvalStatus
    constructionArea
  }
  ${FILE_ALL_FIELDS}
  ${MEDIA_UNION}
`;
export const REAL_ESTATE_ALL_FIELDS_FRAGMENT_BASIC = gql`
  fragment RealEstateAllFieldsBasic on RealEstate {
    id
    realEstateType
    demandEstate
    realEstateId
    type
    province {
      id
      name
    }
    district {
      id
      name
    }
    ward {
      id
      name
    }
    street {
      id
      name
    }
    clearanceArea
    fullAddress
    isForSell
    houseNumber
    hasAlley
    alleyTurnCount
    timeForRent
    timeForRentUnit
    frontHomeAlleyWidth
    isNewConstruction
    width
    length
    rear
    area
    floorCount
    buildingType
    bedRoomArea
    villaView
    price
    tnccPrice
    priceUnit
    bedroomCount
    block
    onFloor
    motelName
    buildingType
    roomArea
    roomNumber
    toiletCount
    direction
    project
    postTitle
    postContent
    privateNote
    legals
    legalMediaUrls {
      ...FileAllFields
    }
    contactName
    contactPhones
    contactPhone1
    contactPhone2
    contactEmail
    mediaUrls {
      ...FileAllFields2
    }
    postType
    startShowTime
    endShowTime
    isSaved
    isAuthorizedRe
    isSaleOffRE
    industryTrade
    creator {
      id
      type
      fullName
      gender
      avatar {
        ...FileAllFields
      }
    }
    map {
      type
      coordinates
    }
    fixedUtilities
    utilities
    localUtilities
    equipmentsAndServices
    investor
    shophouseField
    buildingType
    hasSeaView
    hotelStar
    roomCount
    warehouseType
    warehouseRoomCount
    hasMezzanine
    motelName
    createdAt
    updatedAt
  }
  ${FILE_ALL_FIELDS}
  ${MEDIA_UNION}
`;
export const REAL_ESTATE_ALL_FIELDS_FRAGMENT_FOR_PUBLISH = gql`
  fragment RealEstateAllFieldsForPublish on RealEstate {
    id
    realEstateType
    type
    province {
      id
      name
    }
    district {
      id
      name
    }
    ward {
      id
      name
    }
    street {
      id
      name
    }
    isForSell
    houseNumber
    hasAlley
    alleyTurnCount
    frontHomeAlleyWidth
    isNewConstruction
    width
    length
    rear
    area
    floorCount
    buildingType
    bedRoomArea
    price
    bedroomCount
    block
    onFloor
    motelName
    toiletCount
    direction
    project
    postTitle
    postContent
    privateNote
    legals
    contactName
    contactPhones
    contactPhone1
    contactPhone2
    contactEmail
    mediaUrls {
      ...FileAllFields2
    }
    postType
    startShowTime
    endShowTime
    industryTrade
    map {
      type
      coordinates
    }
    fixedUtilities
    utilities
    localUtilities
    equipmentsAndServices
    investor
    hotelStar
    warehouseType
    hasMezzanine
    motelName
    villaView
    postStatus
    constructionArea
    clearanceArea
  }
  ${MEDIA_UNION}
`;
export const REAL_ESTATE_PROJECT_ALL_FIELDS_V2_FRAGMENT = gql`
  fragment RealEstateProjectAllFieldsV2 on RealEstateProject {
    demandEstate
    realEstateId
    type
    fullAddress
    isForSell
    isIncognito
    realEstateType
    priceUnit
    investor
    projectName
    blockName
    postTitle
    privateNote
    directions
    contactName
    contactPhone1
    postType
    startShowTime
    endShowTime
    tradingStatus
    postStatus
    exclusiveSupplier
    area
    blockCount
    constructionArea
    projectScale
    projectSubType
    floorCountRange {
      key
      value
      from
      to
      unit
    }
    apartment {
      areaByStageList {
        areaType
        stages {
          stageName
          price
          unitPrice
        }
      }
    }
    townhouse {
      areaByStageList {
        areaType
        stages {
          stageName
          price
          unitPrice
        }
      }
    }
    independentVilla {
      areaByStageList {
        areaType
        stages {
          stageName
          price
          unitPrice
        }
      }
    }
    doubleVilla {
      areaByStageList {
        areaType
        stages {
          stageName
          price
          unitPrice
        }
      }
    }
    condotel {
      areaByStageList {
        areaType
        stages {
          stageName
          price
          unitPrice
        }
      }
    }
    penthouse {
      areaByStageList {
        areaType
        stages {
          stageName
          price
          unitPrice
        }
      }
    }
    offictel {
      areaByStageList {
        areaType
        stages {
          stageName
          price
          unitPrice
        }
      }
    }
    commercialArea {
      areaByStageList {
        areaType
        stages {
          stageName
          price
          unitPrice
        }
      }
    }
    width
    length
    rear
    priceRange {
      key
      value
      from
      to
      unit
    }
    areaRangeList {
      key
      value
      from
      to
      unit
    }
    priceByGap {
      stageName
      price
      unitPrice
    }
    legals
    mediaUrlsIds
    tags
    isSaved
    saveItem {
      id
      note
      belongsToCollection {
        id
      }
    }
  }
`;

export const GET_ALL_APPOINTMENT = gql`
  fragment getAllAppointment on Appointment {
    address
    contactName
    createdAt
    deletedBy
    deletedNote
    id
    meetTime
    note
    owner
    phone
    recipient
    relatedRealEstate
    updatedAt
  }
`;
