import React from 'react';

export const InnerChatContext = React.createContext({
  conversationId: '',
  setConversationId: () => {},
  participants: '',
  setParticipants: () => {},
  myId: '',
  setShowChat: () => {},
  showChat: false,
  showRenameInput: false,
  setShowRenameInput: () => {},
  showSearch: false,
  setShowSearch: () => {},
  dataSearch: null,
  setDataSearch: () => {},
  showDataSearch: false,
  setShowDataSearch: () => {},
});

export const useInnerChatContext = () => React.useContext(InnerChatContext);
