import React, {
  useState,
  useEffect,
  useRef,
  TextareaHTMLAttributes,
  useImperativeHandle,
} from 'react';

type Props = {
  customRef?: any,
} & TextareaHTMLAttributes<HTMLTextAreaElement>;

const AutoTextArea = (props: Props, ref) => {
  const { customRef } = props;

  const textAreaRef = useRef(null);
  const [text, setText] = useState('');
  const [textAreaHeight, setTextAreaHeight] = useState('auto');
  const [parentHeight, setParentHeight] = useState('auto');

  useImperativeHandle(ref, () => ({
    setHeight: () => {
      setParentHeight(`40px`);
      setTextAreaHeight(`40px`);
    },
  }));

  useEffect(() => {
    setParentHeight(`${textAreaRef.current?.scrollHeight}px`);
    setTextAreaHeight(`${textAreaRef.current?.scrollHeight}px`);
  }, [text]);

  const onChangeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextAreaHeight('auto');
    setParentHeight(`${textAreaRef.current?.scrollHeight}px`);
    setText(event.target.value);

    if (props.onChange) {
      props.onChange(event);
    }
  };
  return (
    <div
      className="w-full"
      style={{
        minHeight: parentHeight,
      }}>
      <textarea
        {...props}
        ref={(component) => {
          textAreaRef.current = component;
          customRef.current = component;
        }}
        rows={1}
        style={{
          height: textAreaHeight,
        }}
        onChange={onChangeHandler}
      />
    </div>
  );
};

export default React.forwardRef(AutoTextArea);
