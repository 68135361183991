import React, { useEffect, useMemo, useState } from 'react';
import Axios from 'axios';

const ApiURL = 'https://getopengraph.herokuapp.com/';
const REGEX = new RegExp(
  '^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$',
);

type Props = {
  url: string,
};

export default function PreviewLink({ url }: Props) {
  const [data, setData] = useState(null),
    [loading, setLoading] = useState(false);

  useEffect(() => {
    url && url.match(REGEX) !== null && getData(url);
  }, [url]);

  const getData = async (url) => {
    setLoading(true);
    let response = await Axios.get(`${ApiURL}?url=${url}`, {
      method: 'GET',
    });
    setData(response.data);
    setLoading(false);
  };

  const renderUI = useMemo(() => {
    return (
      <p className="flex flex-1 p-4px rounded-rd8px flex-col">
        <a
          target="_blank"
          href={url}
          className="text-14 font-text text-white underline"
          onClick={() => window.open(url)}
          rel="noreferrer">
          {data?.url || url}
        </a>
        {data?.img?.length > 0 && (
          <img
            alt="img"
            src={data?.img}
            className="w-full h-80px mt-16px rounded-rd8px"
          />
        )}
        <a
          href={url}
          target="_blank"
          className="text-14 font-text text-white mt-16px truncate"
          rel="noreferrer">
          {data?.title || 'không tìm thấy'}
        </a>
      </p>
    );
  }, [data, url]);
  return !loading ? (
    data?.status === 200 && data && renderUI
  ) : (
    <p
      onClick={() => window.open(url)}
      className="bodyText cursor-pointer text-white underline">
      {url}
    </p>
  );
}
