const BASE_URL = process.env.BASE_URL || 'https://realtech247.com';

const title = 'REALTECH247 | Nền tảng bất động sản công nghệ';
const description = '';
const keywords = 'realtech,realtech247,timnhachinhchu,bds,batdongsan,nhadat';
const SEO = {
  title,
  description,
  canonical: BASE_URL,
  titleTemplate: '%s',
  keywords,
  openGraph: {
    type: 'website',
    locale: 'vi_VN',
    url: BASE_URL,
    title,
    description,
    images: [
      {
        url: '/images/gif-thumbnail.jpg',
        alt: title,
        width: 1200,
        height: 630,
      },
    ],
  },
};

export default SEO;
