export const realEstateType = {
  townhouse: 'townhouse',
  villa: 'villa',
  building: 'building',
  hotel: 'hotel',
  warehouse: 'warehouse',
  land: 'land',
  apartment: 'apartment',
  shophouse: 'shophouse',
  penthouse: 'penthouse',
  motel: 'motel',
  condotel: 'condotel',
};

export const realEstateProjectType = {
  complex: 'complex',
  landProject: 'landProject',
  industrialArea: 'industrialArea',
  buildingProject: 'buildingProject',
  shoppingMall: 'shoppingMall',
};

export const realEstateTypeConfigs = {
  ...realEstateType,
  ...realEstateProjectType,
};

export const trading = 'trading',
  stop = 'stop';

export const buildingTypeConfigs = [
  {
    key: 'typeA',
    value: 'Loại A',
  },
  {
    key: 'typeB',
    value: 'Loại B',
  },
  {
    key: 'typeC',
    value: 'Loại C',
  },
];

export const estate = 'estate',
  need_estate = 'need_estate';

export const need_buy = 'need_buy',
  need_rent = 'need_rent';

export const tradingStatusSelections = [
  {
    key: trading,
    value: 'Đang giao dịch',
  },
  {
    key: stop,
    value: 'Ngưng giao dịch',
  },
];

export const timeToRentConfigs = [
  {
    key: 'less6M',
    from: 0,
    to: 6,
    value: '< 6 tháng',
  },
  {
    key: '6MTo1Y',
    from: 6,
    to: 12,
    value: '6 tháng - 1 năm',
  },
  {
    key: '1YTo2Y',
    from: 12,
    to: 24,
    value: '1 năm - 2 năm',
  },
  {
    key: '2YTo3Y',
    from: 24,
    to: 36,
    value: '2 năm - 3 năm',
  },
  {
    key: '3YTo4Y',
    from: 36,
    to: 48,
    value: '3 năm - 4 năm',
  },
  {
    key: '4YTo5Y',
    from: 48,
    to: 60,
    value: '4 năm - 5 năm',
  },
  {
    key: '5YTo6Y',
    from: 60,
    to: 72,
    value: '5 năm - 6 năm',
  },
  {
    key: '6YTo7Y',
    from: 72,
    to: 84,
    value: '6 năm - 7 năm',
  },
  {
    key: '7YTo8Y',
    from: 84,
    to: 96,
    value: '7 năm - 8 năm',
  },
  {
    key: '8YTo9Y',
    from: 96,
    to: 108,
    value: '8 năm - 9 năm',
  },
  {
    key: '9YTo10Y',
    from: 108,
    to: 120,
    value: '9 năm - 10 năm',
  },
];

export const actionTypeConfigs = {
  createPost: 'createPost',
  renewPost: 'renewPost',
  updatePost: 'updatePost',
};
