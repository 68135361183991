import { gql } from '@apollo/client';

export const CONVERSATION_UPDATED = gql`
  subscription ($conversationId: ObjectID) @api(name: chat) {
    conversationUpdated(conversationId: $conversationId) {
      id
      name
      participants {
        userId
        nickname
      }
      createdAt
      updatedAt
      pinnedAt
      blockedBy
      lastMessage {
        id
        loading @client
        error @client
        text
        from
        to
        type
        attachments {
          type
          attachmentId
          url
        }
        seenBy
        createdAt
        updatedAt
        callPayload
      }
      typing
    }
  }
`;
