import styles from '@styles/components/Chat.module.css';

type Props = {
  isSend?: boolean,
};

export default function MessageLoading({ isSend }: Props) {
  return (
    <div
      className={`w-full flex flex-col ${
        isSend ? 'items-end' : 'items-start'
      } animate-pulse`}>
      <div
        style={{ width: 150 }}
        className={`${styles.messageLoading} ${
          isSend ? 'bg-blue-main' : 'bg-blue-400'
        }`}>
        <div
          className={`h-4px ${
            isSend ? 'bg-blue-400' : 'bg-white'
          } rounded w-3/4`}
        />
        <div
          className={`h-4px ${
            isSend ? 'bg-blue-400' : 'bg-white'
          } rounded mt-4px`}
        />
        <div
          className={`h-4px ${
            isSend ? 'bg-blue-400' : 'bg-white'
          } rounded w-5/6 mt-4px`}
        />
      </div>
    </div>
  );
}
