import styles from '@styles/components/Chat.module.css';
import { useFunctionChat } from '@utils/hooks/useFunctionChat';

type Props = {
  close?: Function,
  context?: {
    conversationId?: string,
    isHide?: boolean,
    setShowRenameInput?: Function,
    setShowSearch?: Function,
    setShowModal?: Function,
    blockedBy?: boolean,
    setConversationId?: Function,
  },
};

export default function MoreAction(props: Props) {
  const {
    context: {
      conversationId,
      setShowRenameInput,
      setShowSearch,
      setShowModal,
      blockedBy,
      setConversationId,
      isHide,
    },
    close,
  } = props;
  const { onPinConversation, onRemoveConversation, onBlockConversation } =
    useFunctionChat({ conversationId, setConversationId, close });

  return (
    <div className={styles.containerMoreAction}>
      <div
        className={`${styles.itemMore} bodyText border-b sm:border-0 border-gray-300`}
        onClick={onPinConversation}>
        Ghim tin nhắn
      </div>
      {!isHide && (
        <div
          onClick={() => {
            close();
            setShowSearch(true);
          }}
          className={`${styles.itemMore} bodyText border-b sm:border-0 border-gray-300`}>
          Tìm kiếm tin nhắn
        </div>
      )}
      {!isHide && (
        <div
          onClick={() => {
            close();
            setShowRenameInput(true);
          }}
          className={`${styles.itemMore} bodyText border-t-0 sm:border-t border-b sm:border-b-0 border-gray-300 sm:border-blue-main `}>
          Đổi biệt hiệu
        </div>
      )}
      <div
        onClick={() => {
          setShowModal(true);
          close();
        }}
        className={`${styles.itemMore} bodyText border-b sm:border-0 border-gray-300`}>
        Xem trang cá nhân
      </div>
      <div
        onClick={onBlockConversation}
        className={`${styles.itemMore} bodyText border-b sm:border-0 border-gray-300`}>
        {blockedBy ? 'Bỏ chặn người dùng này' : 'Chặn người dùng này'}
      </div>
      <div
        onClick={onRemoveConversation}
        className={`${styles.itemMore} bodyText border-t-0 sm:border-t border-b sm:border-b-0 border-gray-300 sm:border-blue-main`}>
        Xóa tất cả tin nhắn
      </div>
    </div>
  );
}
