import { EmptyConversation } from '@components/ChatMobile/components/EmptyConversation';
import BrokerLoading from '@components/Chat/components/BrokerLoading';
import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { useChatContext } from '@utils/context/chatContext';
import PeopleMobile from '@components/ChatMobile/components/PeopleMobile';
type RenderConversationsProps = {
  listConservations?: Array,
  loading?: boolean,
};
export default function RenderConversations({
  listConservations,
  loading,
}: RenderConversationsProps) {
  const { setConversationId, setParticipants, setShowInnerChatMobile } =
    useChatContext();
  return (
    <div className="flex-1 h-screen">
      <div className="w-full h-full flex flex-col flex-1 overflow-y-auto table table-fixed bg-white">
        {listConservations?.map((item, index) => {
          if (loading) {
            return <BrokerLoading key={`loading-${index}`} />;
          } else {
            return (
              <PeopleMobile
                {...item}
                conversationId={item?.id}
                key={item.id}
                onPress={async () => {
                  await setParticipants(item?.participants);
                  await setConversationId(item?.id);
                  await setShowInnerChatMobile(true);
                }}
              />
            );
          }
        })}
      </div>
      {isEmpty(listConservations) && !loading && <EmptyConversation />}
    </div>
  );
}
