import styles from '@styles/components/Chat.module.css';
import React from 'react';
import { useQuery } from '@apollo/client';
import { READ_MEDIA_URLS } from '@schemas/mutations/image';

type Props = {
  files?: Array,
  onPress?: Function,
};

export default function RenderImage({ files, onPress }: Props) {
  const countFiles = files?.length;
  return (
    <div
      onClick={onPress}
      className={`grid-cols-${
        countFiles > 3 ? '4' : `${countFiles}`
      } grid gap-4px mt-4px cursor-pointer`}>
      {files?.map((item) => {
        return <Image key={item.fileId} fileId={item.fileId} />;
      })}
    </div>
  );
}

type ImageProps = {
  fileId?: string,
};

function Image({ fileId }: ImageProps) {
  const { data } = useQuery(READ_MEDIA_URLS, {
    variables: {
      fileId,
    },
    skip: !fileId,
  });
  const videoUrls = data?.readMediaUrls?.urls?.find(
    (e) => e.type === 'thumbnailSd',
  )?.url;
  const originalUrl =
    data?.readMediaUrls?.type === 'video'
      ? videoUrls
      : data?.readMediaUrls?.originalUrl;

  return (
    <div
      style={{
        backgroundImage: `url("${originalUrl}")`,
        backgroundColor:
          data?.readMediaUrls?.type === 'video' && !videoUrls
            ? 'rgba(0,0,0,0.8)'
            : undefined,
      }}
      className={`${styles.image} relative flex items-center justify-center`}>
      {data?.readMediaUrls?.type === 'video' && (
        <img
          className="w-32px h-32px absolute"
          alt="play"
          src="/svg/play.svg"
        />
      )}
    </div>
  );
}
