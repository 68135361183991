import React from 'react';

export const AppContext = React.createContext({
  isLogin: false,
  setIsLogin: () => {},
  myId: '',
  setMyId: '',
  setShowSaveSuccess: () => {},
});

export const useAppContext = () => React.useContext(AppContext);
