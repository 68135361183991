import { gql } from '@apollo/client';
import { MESSAGE_ALL_FIELDS } from '../fragments';
export const CONVERSATIONS = gql`
  query conversations($offset: Int, $limit: Int, $search: String)
  @api(name: chat) {
    conversations(offset: $offset, limit: $limit, search: $search) {
      hasNextPage
      hasPrevPage
      limit
      nextPage
      offset
      prevPage
      total
      totalPages
      items {
        ...MessageAllFields
      }
    }
  }
  ${MESSAGE_ALL_FIELDS}
`;

export const MESSAGES = gql`
  query messages($conversationId: ObjectID!, $offset: Int, $limit: Int)
  @api(name: chat) {
    messages(conversationId: $conversationId, offset: $offset, limit: $limit) {
      hasNextPage
      hasPrevPage
      limit
      nextPage
      offset
      prevPage
      total
      totalPages
      items {
        id
        from
        to
        text
        type
        loading @client
        error @client
        attachments {
          attachmentId
          type
          url
        }
        callPayload
        seenBy
        createdAt
        updatedAt
      }
    }
  }
`;
export const SEARCH = gql`
  query search($conversationId: ObjectID!, $keyword: String!) @api(name: chat) {
    search(conversationId: $conversationId, keyword: $keyword) {
      id
      from
      to
      text
      type
      attachments {
        attachmentId
        type
        url
      }
      seenBy
      hiddenBy
      unreadBy
      createdAt
    }
  }
`;
