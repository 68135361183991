import { useMutation } from '@apollo/client';
import { useCallback, useState } from 'react';
import { v4 as uuidV4 } from 'uuid';
import { UPLOAD_IMAGE } from '@schemas/mutations/image';
import { toast } from 'react-toastify';
import MyToast from '@components/MyToast';
import { defaultToastOption } from '@utils/configs/Toast';

export const useFunctionUploadFile = (listFiles, setFiles = {}, maxFile) => {
  const [success, setSuccess] = useState(false);
  const [doUpload, { loading }] = useMutation(UPLOAD_IMAGE);
  const uploadSingleFile = useCallback(
    async (toUploadFile) => {
      const temporaryId = uuidV4();
      try {
        await setFiles.push({
          fileId: temporaryId,
          url: URL.createObjectURL(toUploadFile),
          name: toUploadFile.name,
          loading: true,
          progress: 0,
          type: toUploadFile.type.includes('video')
            ? 'video'
            : toUploadFile.type.includes('image')
            ? 'image'
            : 'file',
        });
        const { data } = await doUpload({
          variables: {
            file: toUploadFile,
          },
          context: {
            fetchOptions: {
              onUploadProgress: (progress) => {
                setFiles.update((e) => e.fileId === temporaryId, {
                  fileId: temporaryId,
                  url: URL.createObjectURL(toUploadFile),
                  loading: true,
                  progress: progress.loaded / progress.total,
                  type: toUploadFile.type.includes('video')
                    ? 'video'
                    : toUploadFile.type.includes('image')
                    ? 'image'
                    : 'file',
                });
              },
            },
          },
        });
        await setFiles?.update((e) => e.fileId === temporaryId, {
          fileId: data.singleUpload.payload.fileId,
          url: URL.createObjectURL(toUploadFile),
          name: toUploadFile.name,
          type: toUploadFile.type.includes('video')
            ? 'video'
            : toUploadFile.type.includes('image')
            ? 'image'
            : 'file',
        });
        if (data.singleUpload.payload.fileId) {
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false);
          }, 300);
        }
      } catch (e) {
        console.log(e);
      }
    },
    [doUpload, setFiles],
  );
  let Promise = require('es6-promise').Promise;
  let count = 0;
  const doUploadImage = useCallback(
    async (files) => {
      if (maxFile && files?.length + listFiles?.length > maxFile) {
        toast(
          <MyToast type="warning" message="Số file vượt quá qui định." />,
          defaultToastOption,
        );
      } else {
        files.forEach(function (element) {
          if (
            element?.type?.includes('video') &&
            parseFloat(element?.size / 1048576).toFixed(0) >= 10
          ) {
            count = count + 1;
          } else {
            count = 0;
          }
        });
        if (count > 0) {
          toast(
            <MyToast type="warning" message="Video không được quá 10MB" />,
            defaultToastOption,
          );
        } else {
          await Promise.all(files.map(uploadSingleFile));
        }
      }
    },
    [listFiles, uploadSingleFile],
  );

  return { doUploadImage, loading, success };
};
