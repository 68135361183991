import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;
const ENV = process.env.NODE_ENV;

if (ENV === 'production' || ENV === 'staging') {
  Sentry.init({
    environment: ENV,
    dsn: SENTRY_DSN,
    tracesSampleRate: 1.0,
  });
}
