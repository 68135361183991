import { isMobile } from '@utils/helper';

export const defaultToastOption = {
  position: isMobile ? 'top-center' : 'bottom-right',
  autoClose: 1500,
  closeButton: false,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};
