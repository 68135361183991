import gql from 'graphql-tag';

export const UPLOAD_IMAGE = gql`
  mutation UploadImage($file: Upload!) {
    singleUpload(file: $file) {
      payload {
        fileId
        type
        urls {
          type
          url
        }
      }
    }
  }
`;
export const ADD_YOUTUBE_VIDEO = gql`
  mutation AddYoutubeVideo($youtubeUrl: String!) @api(name: image) {
    addYoutubeVideo(youtubeUrl: $youtubeUrl) {
      success
      msg
      payload {
        fileId
        type
        youtubeMetadata {
          videoId
          title
          description
          thumbnailUrl
        }
      }
    }
  }
`;
export const READ_MEDIA_URLS = gql`
  query readMediaUrls($fileId: ObjectID!) @api(name: image) {
    readMediaUrls(fileId: $fileId) {
      ... on File {
        id
        fileId
        type
        urls {
          type
          url
        }
        type
        originalKey
        originalUrl
        createdAt
      }
      ... on YoutubeFile {
        fileId
        type
        youtubeMetadata {
          videoId
          title
          description
          thumbnailUrl
        }
      }
    }
  }
`;
