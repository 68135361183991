import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { MESSAGES } from '@schemas/queries/chat';
import { CONVERSATION_UPDATED } from '@schemas/subcriptions/chat';
import { mergeDeep } from '@utils/helper';

export const useGetMessages = (conversationId, participants, commonId) => {
  const [isLoadMore, setIsLoadMore] = useState(false);
  const variables = useMemo(() => {
    return {
      offset: 0,
      conversationId,
    };
  }, [conversationId]);
  const { data, subscribeToMore, loading, fetchMore, refetch } = useQuery(
    MESSAGES,
    {
      variables,
      skip: !conversationId,
    },
  );

  useEffect(() => {
    refetch();
    subscribeToMore({
      document: CONVERSATION_UPDATED,
      variables: { conversationId },
      updateQuery: (prev, { subscriptionData }) => {
        if (
          !prev ||
          !subscriptionData?.data?.conversationUpdated?.lastMessage
        ) {
          return prev;
        }
        const { conversationUpdated } = subscriptionData.data,
          { lastMessage } = conversationUpdated,
          { id: incomingMessageId, from } = lastMessage;
        const {
            messages: { items },
          } = prev,
          existedMessages = items.find((e) => e.id === incomingMessageId);
        if (!existedMessages && commonId !== from) {
          setTimeout(() => {
            const chatBox = document.getElementById('chat-box');
            chatBox.scrollTop = chatBox.scrollHeight;
          }, 500);
          return {
            ...prev,
            messages: {
              ...prev.messages,
              items: [lastMessage, ...prev.messages.items],
            },
          };
        }
        return prev;
      },
    });
  }, [subscribeToMore, conversationId, commonId, refetch]);

  const messages = data?.messages?.items || [];

  const seenByByParticipants = {};
  if (participants) {
    for (const participant of participants) {
      const { userId } = participant;
      seenByByParticipants[userId] = messages.find((e) =>
        e.seenBy.includes(userId),
      );
    }
  }

  const hasNextPage = data?.messages?.hasNextPage;

  const loadMore = useCallback(async () => {
    if (!hasNextPage || isLoadMore) {
      return;
    }
    await setIsLoadMore(true);
    await fetchMore({
      variables: {
        ...variables,
        offset: (data.messages?.items.length || 0) + 1,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }
        return mergeDeep(prev, fetchMoreResult);
      },
    });
    await setIsLoadMore(false);
  }, [data, fetchMore, variables, isLoadMore]);

  return {
    data,
    loading,
    isLoadMore,
    loadMore,
    subscribeToMore,
    seenByByParticipants,
    hasNextPage,
  };
};
