import moment from 'moment/moment';

type Props = {
  time?: string,
};
export function SpaceTime({ time }: Props) {
  return (
    <div
      className="py-6 flex flex-row items-center justify-center"
      style={{
        width: '100%',
      }}>
      <div className="border-b border-gray-300 flex-1" />
      <div className="px-2 text-gray-400 text-sm text-center">
        {moment(time).format('HH:mm A, DD/MM/YYYY')}
      </div>
      <div className="border-b border-gray-300 flex-1" />
    </div>
  );
}
