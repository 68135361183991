import React from 'react';
import { useInterval } from 'react-use';

export const useForceUpdate = () => {
  const [, updateState] = React.useState(0);
  return React.useCallback(() => {
    updateState((state) => state + 1);
  }, []);
};

export const useIntervalForceUpdate = (interval) => {
  const forceUpdate = useForceUpdate();
  return useInterval(forceUpdate, interval);
};
