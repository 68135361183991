import gql from 'graphql-tag';

export const CREATE_CONVERSATION = gql`
  mutation createConversation($userIds: [ObjectID!]!) @api(name: chat) {
    createConversation(userIds: $userIds) {
      success
      msg
      payload {
        id
        name
        participants {
          userId
          nickname
        }
        createdAt
        updatedAt
        lastMessage {
          id
          text
          attachments {
            type
            attachmentId
          }
        }
        typing
        pinnedAt
        blockedBy
      }
    }
  }
`;

export const SEND_MESSAGE = gql`
  mutation sendMessage(
    $recipient: RecipientInput!
    $message: MessageInput
    $senderAction: SenderAction
  ) @api(name: chat) {
    sendMessage(
      recipient: $recipient
      message: $message
      senderAction: $senderAction
    ) {
      success
      msg
      payload {
        id
        name
        participants {
          userId
          nickname
        }
        createdAt
        updatedAt
        lastMessage {
          id
          loading @client
          error @client
          from
          to
          text
          type
          attachments {
            attachmentId
            type
            url
          }
          seenBy
          createdAt
          updatedAt
          callPayload
        }
        typing
      }
    }
  }
`;

export const MARK_MESSAGE_UNREAD = gql`
  mutation markMessageUnread($conversationId: ObjectID!) @api(name: chat) {
    markMessageUnread(conversationId: $conversationId) {
      msg
    }
  }
`;
export const REMOVE_CONVERSATION = gql`
  mutation removeConversation($conversationId: ObjectID!) @api(name: chat) {
    removeConversation(conversationId: $conversationId) {
      msg
      success
    }
  }
`;
export const REMOVE_MESSAGE = gql`
  mutation removeMessage($messageId: ObjectID!) @api(name: chat) {
    removeMessage(messageId: $messageId) {
      msg
      success
    }
  }
`;
export const HIDE_MESSAGE = gql`
  mutation hideMessage($messageId: ObjectID!) @api(name: chat) {
    hideMessage(messageId: $messageId) {
      msg
      success
    }
  }
`;
export const BLOCK_CONVERSATION = gql`
  mutation blockConversation($conversationId: ObjectID!) @api(name: chat) {
    blockConversation(conversationId: $conversationId) {
      msg
      success
    }
  }
`;
export const SET_NICK_NAME = gql`
  mutation setNickname($conversationId: ObjectID!, $nickname: String!)
  @api(name: chat) {
    setNickname(conversationId: $conversationId, nickname: $nickname) {
      msg
      payload {
        id
        participants {
          id
          nickname
          userId
        }
      }
    }
  }
`;

export const PIN_CONVERSATION = gql`
  mutation pinConversation($conversationId: ObjectID!) @api(name: chat) {
    pinConversation(conversationId: $conversationId) {
      msg
    }
  }
`;
