import dynamic from 'next/dynamic';
const ModalWrapper = dynamic(() => import('@components/ModalWrapper'));
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { useEffect, useRef, useState } from 'react';
import { getMedias } from '@utils/helper';
import styles from '@styles/components/Carousel.module.scss';

type Props = {
  open?: boolean,
  onClose?: Function,
  listMedia?: Array,
};

export default function ModalViewMedia({
  open,
  onClose,
  listMedia = [],
}: Props) {
  const ref = useRef(null);
  const [files, setFiles] = useState([]);
  useEffect(() => {
    (async function () {
      const data = await getMedias(listMedia?.map((e) => e.fileId));
      setFiles(data);
    })();
  }, []);
  return (
    <div style={{ zIndex: 100000 }}>
      <ModalWrapper
        className="w-full h-700px max-w-98% rounded-rd9px bg-black flex items-center justify-center"
        open={open}
        onClose={onClose}>
        <AliceCarousel
          ref={ref}
          keyboardNavigation={true}
          disableButtonsControls={true}
          animationType={'slide'}
          animationDuration={500}
          fadeOutAnimation={true}>
          {files?.map((item) => {
            if (item?.type === 'image') {
              return (
                <div className="w-full flex items-center justify-center">
                  <img
                    key={item?.originalUrl}
                    src={item?.originalUrl}
                    className="h-400px"
                    alt=""
                  />
                </div>
              );
            } else {
              return (
                <div className="w-full flex items-center justify-center">
                  <video
                    controls
                    key={item?.originalUrl}
                    className="w-full h-300px">
                    <source src={item?.originalUrl} type="video/mp4" />
                  </video>
                </div>
              );
            }
          })}
        </AliceCarousel>
        <button
          aria-label="Prev Slide"
          onClick={() => ref.current.slidePrev()}
          className={`${styles.navigation} ${styles.prev}`}>
          <img src="/svg/arrow_nav.svg" alt="icon" className="w-full h-full" />
        </button>
        <button
          aria-label="Next Slide"
          onClick={() => ref.current.slideNext()}
          className={`${styles.navigation} ${styles.next}`}>
          <img
            src="/svg/arrow_nav.svg"
            alt="icon"
            className={`w-full h-full ${styles.iconNext}`}
          />
        </button>
      </ModalWrapper>
    </div>
  );
}
