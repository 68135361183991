import styles from '@styles/components/Chat.module.css';
import { useSendMessage } from '@hooks/Chat/useSendMessage';
import { useCallback, useRef, useState } from 'react';
import useList from 'react-use/lib/useList';
import AutoTextArea from '@components/AutoTextArea';
import { useDropzone } from 'react-dropzone';
import { useFunctionUploadFile } from '@utils/hooks/useFunctionUploadFile';
import ImageFile from '@components/Chat/components/ImageFile';
import dynamic from 'next/dynamic';

const EmojiPicker = dynamic(() => import('emoji-picker-react'), { ssr: false });
type Props = {
  conversationId?: string,
  textAreaRef?: any,
};

export default function EnterMessage({ conversationId, textAreaRef }: Props) {
  const [content, setContent] = useState(''),
    [files, setFiles] = useList([]),
    [shouldShowEmoji, setShouldShowEmoji] = useState(false);
  const inputRef = useRef(null);

  const { onSendMessage } = useSendMessage({
    conversationId,
    setContent,
    attachments: [...(files || [])],
    setAttachments: setFiles,
    inputRef,
    textAreaRef,
  });

  const { doUploadImage, loading, success } = useFunctionUploadFile(
    files,
    setFiles,
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: ['image/*', 'video/*'],
    multiple: true,
    onDrop: doUploadImage,
  });
  // const { getRootProps: rootProps, getInputProps: inputProps } = useDropzone({
  //   accept: [
  //     'image/*',
  //     'video/*',
  //     'application/vnd.ms-excel',
  //     'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  //     'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  //     'application/pdf',
  //     'application/xhtml+xml',
  //     'application/xml',
  //     'application/msword',
  //     'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  //     'application/octet-stream',
  //   ],
  //   multiple: true,
  //   onDrop: uploadFile,
  // });

  const onKeyDown = useCallback(async (e) => {
    const text = e.target.value.trim();
    if (e.key === 'Enter' && !e.shiftKey) {
      setContent('');
      if (text.length !== 0 || files?.length > 0) {
        if (shouldShowEmoji) {
          setShouldShowEmoji(false);
        }
        await onSendMessage({ nativeEvent: { text: text } });
        setShouldShowEmoji(false);
      } else {
        setContent('');
      }
    } else {
      return false;
    }
  }, []);
  const disabledSendButton = loading && !success;
  return (
    <div className="w-full flex flex-col py-0 sm:py-12px py-2 sm:py-3 items-end border-t border-blue-main px-8px">
      {files?.length > 0 && (
        <div className="w-full pb-2">
          <ImageFile setFiles={setFiles} files={files} />
        </div>
      )}
      <div className="flex w-full flex-row relative">
        <div className={styles.containerMessage}>
          <AutoTextArea
            ref={textAreaRef}
            customRef={inputRef}
            value={content}
            onChange={(e) => {
              if (e.target.value !== '\n') {
                setContent(e.target.value);
              }
            }}
            placeholder="Nhập nội dung tin nhắn"
            className={styles.input}
            maxLength={500}
            onKeyPress={onKeyDown}
          />
          <div className="flex flex-row mr-8px items-end pb-12px space-x-3">
            <button
              onClick={() => setShouldShowEmoji(!shouldShowEmoji)}
              className="w-24px h-24px">
              <img alt="smile" src="/svg/smile.svg" className="flex-1" />
            </button>
            <div {...getRootProps({ className: 'dropzone' })}>
              <input
                {...getInputProps()}
                style={{ display: 'none' }}
                type="file"
              />
              <img
                src="/svg/uploadImg.svg"
                alt="file"
                className="w-24px h-24px"
              />
            </div>
          </div>
        </div>

        {shouldShowEmoji && (
          <EmojiPicker
            disableSearchBar={true}
            pickerStyle={{
              position: 'absolute',
              bottom: 70,
              right: 0,
              overflow: 'hidden',
              backgroundColor: 'white',
              zIndex: 1000,
            }}
            onEmojiClick={(event, emojiObject) => {
              setContent(`${content} ${emojiObject.emoji}`);
            }}
          />
        )}
        <button
          disabled={disabledSendButton}
          className={`w-40px h-40px ml-8px ${
            disabledSendButton && 'opacity-50 cursor-not-allowed'
          }`}
          onClick={async () => {
            if (content.trim().length !== 0 || files?.length > 0) {
              if (shouldShowEmoji) {
                setShouldShowEmoji(false);
              }
              await onSendMessage({ nativeEvent: { text: content } });
            } else {
              setContent('');
            }
          }}>
          <img src="/svg/sendMess.svg" className="flex-1" alt="send" />
        </button>
      </div>
    </div>
  );
}
