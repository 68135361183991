import React from 'react';
import dynamic from 'next/dynamic';
import { ReactChildren } from 'react';
import { DrawerProps } from '@material-ui/core/Drawer';
import { makeStyles } from '@mui/styles';

const Drawer = dynamic(() => import('@material-ui/core/Drawer'), {
  ssr: false,
});

type Props = {
  children: ReactChildren,
  customTopAnchorBottom?: Boolean,
  customPercentTopAnchorBottom?: string,
  customTopAnchorRight?: Boolean,
  customPercentTopAnchorRight?: string,
} & DrawerProps;

export default function DrawerWrapper({
  children,
  anchor = 'bottom',
  customTopAnchorBottom,
  customPercentTopAnchorBottom,
  customTopAnchorRight,
  customPercentTopAnchorRight,

  ...props
}: Props & DrawerProps) {
  const useStyles = makeStyles({
    root: {
      '& .MuiDrawer-paperAnchorBottom': {
        borderTopLeftRadius: '16px',
        borderTopRightRadius: '16px',
        top: customTopAnchorBottom
          ? '5%'
          : customPercentTopAnchorBottom
          ? customPercentTopAnchorBottom
          : null,
      },
      '& .MuiDrawer-paperAnchorRight': {
        borderTopLeftRadius: '16px',
        borderTopRightRadius: '16px',
        maxWidth: '100%',
        minWidth: '100%',
        top: customTopAnchorRight
          ? '5%'
          : customPercentTopAnchorRight
          ? customPercentTopAnchorRight
          : null,
      },
    },
  });
  const classes = useStyles();
  return (
    <Drawer
      style={{
        position: 'absolute',
        top: 0,
        bottom: 0,
      }}
      {...props}
      keepMounted
      container={process.browser && document.getElementById('modal-root')}
      anchor={anchor}
      className={classes.root}>
      {children}
    </Drawer>
  );
}
