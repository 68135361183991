import styles from '@styles/components/MyNetwork/MemberWaitingForApprove.module.css';

import { useQuery } from '@apollo/client';
import { GET_MEMBER_INFO_MY_NETWORK } from '@schemas/queries/users';
import Avatar from '@components/Menu/components/Avatar';
import React, { useMemo, useState } from 'react';
import UserConfiguarations from '@utils/configs/UserConfiguarations/userConfiguarations.json';
import moment from 'moment/moment';
import { fetchObject, isMobile } from '@utils/helper';
import Spinner from '@components/spinner';
import QrCode from '@components/pages/MyInformation/QrCode';
import MyButton from '@components/Button';
import dynamic from 'next/dynamic';
const SharedMyMemberId = dynamic(
  () => import('@components/pages/MyInformation/SharedMyMemberId'),
  { ssr: false },
);

const ModalWrapper = dynamic(() => import('@components/ModalWrapper'));
type Props = {
  userId?: string,
  developerName?: string,
  developerId?: string,
  close?: Function,
  hideDeveloperName?: boolean,
  disableTitle?: boolean,
};

const dateFormat = 'YYYY-MM-DD';

export default function ModalUserInfo(props: Props) {
  const {
    userId,
    developerName,
    developerId,
    hideDeveloperName,
    disableTitle,
  } = props;
  const [showShared, setShowShared] = useState(false);
  const fieldToFetch = useMemo(() => {
    return {
      query: GET_MEMBER_INFO_MY_NETWORK,
      queryResultPath: 'getMemberInfo',
      variables: { id: userId },
    };
  }, [userId]);

  const { query, queryResultPath, variables } = fieldToFetch;

  const { data, loading } = useQuery(query, {
    variables,
    skip: !userId,
  });

  const userData = fetchObject(data, queryResultPath, {});

  const { userType } = UserConfiguarations;

  const { data: dataDeveloper } = useQuery(GET_MEMBER_INFO_MY_NETWORK, {
    variables: {
      id: developerId,
    },
    skip: !developerId,
  });
  const isCompany = userData?.type === 'Company',
    isBroker = userData?.type === 'Broker';

  return (
    <div className="flex flex-col relative overflow-hidden">
      {!disableTitle ? (
        <div className="flex w-full py-16px justify-center border-b border-gray-300">
          <p className="uppercase text-15 font-bold font-textBold">
            Trang cá nhân
          </p>
        </div>
      ) : null}
      <div className="flex lg:flex-row flex-col flex-1 overflow-y-auto items-center lg:items-start py-6 px-4 lg:px-10 space-x-0 lg:space-x-10 space-y-4 lg:space-y-0">
        {loading && (
          <div className="flex w-full justify-center items-center">
            <Spinner />
          </div>
        )}
        {!loading && (
          <>
            <div className="flex flex-col space-y-2">
              <Avatar
                avatarId={userData?.avatar?.fileId}
                type={userData?.type}
                wrapperClass="w-135px h-135px"
              />
              <div className="uppercase text-15 font-bold text-blue-main text-center">
                {userData?.fullName}
              </div>
            </div>
            <div className="w-full flex-1 flex flex-col lg:overflow-hidden space-y-4">
              <div className="text-base font-bold text-blue-main text-center">
                THÔNG TIN CÁ NHÂN
              </div>
              <div className=" border border-blue-main rounded-rd8px flex-1 flex flex-col overflow-hidden">
                <RowField
                  title="Vai trò"
                  value={userType.find((e) => e.key === userData?.type)?.value}
                />
                {!hideDeveloperName && (
                  <RowField
                    title="Tên nhà phát triển"
                    value={
                      developerName || dataDeveloper?.getMemberInfo?.fullName
                    }
                  />
                )}
                <RowField
                  title={isCompany ? 'Tên doanh nghiệp' : 'Họ và tên'}
                  value={userData?.fullName}
                />
                <RowField
                  title="Số điện thoại 1"
                  value={userData?.phones?.[0]}
                />
                <RowField
                  title="Giới tính"
                  value={userData?.gender === 'female' ? 'Nữ' : 'Nam'}
                  hideField={isCompany}
                />
                <RowField title="Email" value={userData?.email} />
                <RowField
                  title="Ngày sinh"
                  value={moment(userData?.birthDate, dateFormat).format(
                    'DD/MM/YYYY',
                  )}
                  hideField={
                    isCompany ||
                    !moment(userData?.birthDate, dateFormat).isValid()
                  }
                />
                <RowField
                  title="Ngày thành lập"
                  value={moment(userData?.establishedDate, dateFormat).format(
                    'DD/MM/YYYY',
                  )}
                  hideField={
                    !isCompany ||
                    !moment(userData?.establishedDate, dateFormat).isValid()
                  }
                />
                <RowField
                  title="Tỉnh/ Thành phố"
                  value={userData?.province?.name}
                  hideField={isCompany}
                />
                <RowField
                  title="Thuộc công ty"
                  value={userData?.company}
                  hideField={!isBroker}
                />
              </div>
            </div>
            <div className="w-full sm:w-205px flex flex-col space-y-6">
              <QrCode
                size={isMobile ? 100 : 135}
                memberId={userData?.memberId}
              />
              <MyButton onPress={() => setShowShared(true)} label="Chia sẻ" />
              <ModalWrapper
                className="rounded-rd8px"
                open={showShared}
                onClose={() => setShowShared(false)}>
                <SharedMyMemberId
                  memberId={userData?.memberId}
                  close={() => setShowShared(false)}
                />
              </ModalWrapper>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

type RowFieldProps = {
  title?: string,
  value?: string,
  hideField?: boolean,
};

function RowField(props: RowFieldProps) {
  const { title, value, hideField } = props;

  if (hideField) {
    return null;
  }

  return (
    <div className={styles.rowField}>
      <p className={styles.rowKey}>{title}</p>
      <p className={styles.rowValue}>{value || 'Không có'}</p>
    </div>
  );
}
