import {
  allPostType,
  allProjectType,
  allRealEstateType,
} from '@utils/configs/SearchList/SearchListConfigs';
import uniqBy from 'lodash/uniqBy';
import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';
import { ApolloCache } from '@apollo/client';
import moment from 'moment/moment';
import { isEqual } from 'lodash';
import { estate } from '@utils/configs/RealEstateConfiguarations/realEstate';
import { routeSearchList } from '@utils/configs/SearchList/const';
import { READ_MEDIA_URLS } from '@schemas/mutations/image';
import { initializeApollo } from '@utils/wire';
import { toast } from 'react-toastify';
import MyToast from '@components/MyToast';

export const myFixed = (x, d) => {
  if (!d) return x.toFixed(d);
  return x.toFixed(d).replace(/\.?0+$/, '');
};
export const getMediaUrlByType = (mediaObject = {}) => {
  const urls = mediaObject?.urls || [],
    originalUrl = mediaObject?.originalUrl || '',
    foundMediumUrl = urls[0]?.url
      ? urls?.find((e) => e.type === 'medium')?.url
      : '',
    foundSmallUrl = urls[0]?.url
      ? urls?.find((e) => e.type === 'small')?.url
      : '';
  return foundMediumUrl || foundSmallUrl || originalUrl;
};

export function getMediaUrlByType2(mediaObject, type: string) {
  const { originalUrl, urls = [] } = mediaObject || {},
    foundUrl = urls?.find((e) => e.type === type);
  return foundUrl?.url || originalUrl;
}

export const nonAccentVietnamese = (str) => {
  str = str?.toString().toLowerCase() || '';
  //     We can also use this instead of from line 11 to line 17
  //     str = str.replace(/\u00E0|\u00E1|\u1EA1|\u1EA3|\u00E3|\u00E2|\u1EA7|\u1EA5|\u1EAD|\u1EA9|\u1EAB|\u0103|\u1EB1|\u1EAF|\u1EB7|\u1EB3|\u1EB5/g, "a");
  //     str = str.replace(/\u00E8|\u00E9|\u1EB9|\u1EBB|\u1EBD|\u00EA|\u1EC1|\u1EBF|\u1EC7|\u1EC3|\u1EC5/g, "e");
  //     str = str.replace(/\u00EC|\u00ED|\u1ECB|\u1EC9|\u0129/g, "i");
  //     str = str.replace(/\u00F2|\u00F3|\u1ECD|\u1ECF|\u00F5|\u00F4|\u1ED3|\u1ED1|\u1ED9|\u1ED5|\u1ED7|\u01A1|\u1EDD|\u1EDB|\u1EE3|\u1EDF|\u1EE1/g, "o");
  //     str = str.replace(/\u00F9|\u00FA|\u1EE5|\u1EE7|\u0169|\u01B0|\u1EEB|\u1EE9|\u1EF1|\u1EED|\u1EEF/g, "u");
  //     str = str.replace(/\u1EF3|\u00FD|\u1EF5|\u1EF7|\u1EF9/g, "y");
  //     str = str.replace(/\u0111/g, "d");
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str.replace(/đ/g, 'd');
  // Some system encode vietnamese combining accent as individual utf-8 characters
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ''); // Huyền sắc hỏi ngã nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ''); // Â, Ê, Ă, Ơ, Ư
  return str;
};

export function handleParamsRealEstate({
  realEstateType,
  postType,
  typeOfDemand,
}) {
  const realEstateVariables = {
    realEstateType:
      realEstateType === allRealEstateType ? null : [realEstateType],
    postType: postType === allPostType ? null : [postType],
    typeOfDemand: typeOfDemand === 'project' ? null : typeOfDemand,
    sort: { createdAt: -1 },
  };
  return { ...pickBy(realEstateVariables, (e) => e) };
}

export function handleParamsRealEstateProject({ realEstateType, postType }) {
  const realEstateVariables = {
    realEstateType: realEstateType === allProjectType ? null : [realEstateType],
    postType: postType === allPostType ? null : [postType],
  };
  return { ...pickBy(realEstateVariables, (e) => e) };
}

export function getErrorsFormHook({ error, values }) {
  return {
    values: error ? {} : values,
    errors: error
      ? error.details.reduce((previous, currentError) => {
          return {
            ...previous,
            [currentError.path[0]]: currentError,
          };
        }, {})
      : {},
  };
}

export const entryNumber = (text, setValue, maxLength = 9999) => {
  if (!/^\d+$/.test(text)) {
    setValue(text.replace(/\D+/g, '').substr(0, maxLength));
  } else {
    setValue(text.substr(0, maxLength));
  }
};

export const getRouteSearchList = ({
  typeOfDemand,
  realEstateType,
  postType,
  isAuthorizedRe,
}) => {
  return `/${routeSearchList}/${typeOfDemand}/${realEstateType}/${postType}${
    isAuthorizedRe ? '/?isAuthorizedRe=true' : ''
  }`;
};

export function fetchObject(object = {}, path, defaultValue = '') {
  if (!object) {
    return defaultValue;
  }
  let currentPointer = { ...object };
  const paths = path.split('.');

  for (const key of paths) {
    currentPointer = currentPointer[key];
    if (!currentPointer) {
      return defaultValue;
    }
  }
  return currentPointer || defaultValue;
}

export function mergeDeep(...objects) {
  const isObject = (obj) => obj && typeof obj === 'object';

  return objects.reduce((prev, obj) => {
    Object.keys(obj).forEach((key) => {
      const pVal = prev[key];
      const oVal = obj[key];

      if (Array.isArray(pVal) && Array.isArray(oVal)) {
        prev[key] = uniqBy(pVal.concat(...oVal), 'id');
      } else if (isObject(pVal) && isObject(oVal)) {
        prev[key] = mergeDeep(pVal, oVal);
      } else {
        prev[key] = oVal;
      }
    });

    return prev;
  }, {});
}

export const isMobile = (function () {
  let check = false;
  if (!process.browser) {
    return false;
  }
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a,
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
        a.substr(0, 4),
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
})();
export function numberWithDots(x) {
  if (!x) {
    return '0';
  }
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}
export function numberWithSpace(x) {
  return x?.toString().replace(/\B(?=(\d{2})+(?!\d))/g, ' ');
}
const getUnit = (index) => {
  switch (index) {
    case 1:
      return 'ngàn';
    case 2:
      return 'triệu';
    case 3:
      return 'tỷ';
    case 4:
      return 'ngàn tỷ';
    case 5:
      return 'triệu tỷ';
    case 6:
      return 'tỷ tỷ';
    default:
      return '';
  }
};

export const DocTienBangChuV2 = (money: string) => {
  if (!money || typeof money !== 'string') {
    return '';
  }
  const moneyWithDot = money.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  const splitMoney = moneyWithDot.split('.').reverse();
  let doctien = [];
  splitMoney.forEach((e, index) => {
    const doc =
      parseInt(e, 10) <= 0 && !doctien?.length
        ? ''
        : `${Number(e)} ${getUnit(index)}`.trim();
    if (doc) {
      doctien.push(doc);
    }
  });
  return doctien.reverse().join(' ').trim();
};

export const resetQueries = (queries: [string], cache: ApolloCache) => {
  queries.forEach((query) => {
    cache.evict({
      id: 'ROOT_QUERY',
      fieldName: query,
    });
  });
};

export function getListDaysInMonth(month, year) {
  const dateFormat = 'DD-MM-YYYY';
  let daysInMonth = moment(`${month}-${year}`, 'M-YYYY').daysInMonth();
  const daysArray = [],
    listDayOfLastMonth = [],
    listDayOfNextMonth = [];
  while (daysInMonth) {
    const currentDate = moment(`${month}-${year}`, 'M-YYYY').date(daysInMonth);
    daysArray.push(currentDate.format(dateFormat));
    daysInMonth -= 1;
  }
  const daysInCurrentMonth = [...daysArray.reverse()],
    startDayOfMonthIsoWeekday = moment(
      daysInCurrentMonth[0],
      dateFormat,
    ).isoWeekday(),
    endDayOfMonthIsoWeekday = moment(
      daysInCurrentMonth[daysInCurrentMonth.length - 1],
      dateFormat,
    ).isoWeekday();
  if (startDayOfMonthIsoWeekday !== 1) {
    for (let i = 1; i < startDayOfMonthIsoWeekday; i += 1) {
      const previousDate = moment(daysInCurrentMonth[0], dateFormat)
        .subtract(startDayOfMonthIsoWeekday - i, 'days')
        .format(dateFormat);
      listDayOfLastMonth.push(previousDate);
    }
  }
  if (endDayOfMonthIsoWeekday !== 7) {
    for (let i = 1; i <= 7 - endDayOfMonthIsoWeekday; i += 1) {
      const nextDate = moment(
        daysInCurrentMonth[daysInCurrentMonth.length - 1],
        dateFormat,
      )
        .add(i, 'days')
        .format(dateFormat);
      listDayOfNextMonth.push(nextDate);
    }
  }
  const listDays = [
    ...listDayOfLastMonth,
    ...daysInCurrentMonth,
    ...listDayOfNextMonth,
  ];
  if (listDays.length < 42) {
    for (let i = 1; i <= 42 - listDays.length; i += 1) {
      const temporaryArray = isEmpty(listDayOfNextMonth)
        ? [...daysInCurrentMonth]
        : [...listDayOfNextMonth];
      const nextDate = moment(
        temporaryArray[temporaryArray.length - 1],
        dateFormat,
      )
        .add(1, 'days')
        .format(dateFormat);
      listDayOfNextMonth.push(nextDate);
    }
  }
  return [...listDayOfLastMonth, ...daysInCurrentMonth, ...listDayOfNextMonth];
}

export function compareTheChangeOf2Objects(value1, value2) {
  const toUpdateField = {};
  Object.keys(value1).forEach((key) => {
    if (!isEqual(value1?.[key], value2?.[key])) {
      toUpdateField[key] = value1[key];
    }
  });
  return toUpdateField;
}

export function cropImage(src, startPoint, width, height) {
  const canvas = document.createElement('canvas'),
    ctx = canvas.getContext('2d');
  canvas.width = width;
  canvas.height = height;
  return new Promise((resolve, reject) => {
    const imageToGetSize = new Image();

    imageToGetSize.onload = function () {
      ctx.drawImage(
        imageToGetSize,
        startPoint.x,
        startPoint.y,
        width,
        height,
        0,
        0,
        width,
        height,
      );
      canvas.toBlob(resolve, 'image/jpeg', 0.8);
    };

    imageToGetSize.onerror = function () {
      reject(new Error('Cannot get image'));
    };

    imageToGetSize.src = src;
  });
}

export function getAvatarUrl({ type }) {
  if (type === 'Company') {
    return '/svg/defaultCompany.svg';
  }

  return '/svg/male.svg';
}

export function getTagRealEstate({ isForSell, isNeedToBuy, demandEstate }) {
  if (demandEstate === estate) {
    if (isForSell) {
      return '/svg/TagSale.svg';
    }
    return '/svg/TagRent.svg';
  } else {
    if (isNeedToBuy) {
      return '/svg/TagNeedBuy.svg';
    }
    return '/svg/TagNeedRent.svg';
  }
}

export function interpolate(inputNumber, { inputRange, outputRange }) {
  const firstInput = inputRange[0],
    lastInput = inputRange[1],
    firstOutput = outputRange[0],
    lastOutput = outputRange[1];
  if (inputNumber <= firstInput) {
    return firstOutput;
  } else if (inputNumber >= lastInput) {
    return lastOutput;
  }
  return (
    (inputNumber - firstInput) *
      ((lastOutput - firstOutput) / (lastInput - firstInput)) +
    firstOutput
  );
}

export const convertFloatWithDot = (floatNumber) => {
  if (!floatNumber) {
    return '';
  }
  const convertFloatNumber = floatNumber?.toString(),
    splitNumber = convertFloatNumber.split('.');
  if (splitNumber?.length) {
    return `${numberWithDots(splitNumber[0])}${
      parseInt(splitNumber[1], 10) > 0 ? `,${splitNumber[1]}` : ''
    }`;
  }
  return '';
};

export function convertValueSort(sort) {
  if (!sort) {
    return null;
  }
  if (sort.includes('createdAt')) {
    if (sort === 'createdAt-1') {
      return { postType: 1, createdAt: -1 };
    }
    return { postType: 1, createdAt: 1 };
  }
  if (sort.includes('price')) {
    if (sort === 'price-1') {
      return { postType: 1, price: -1 };
    }
    return { postType: 1, price: 1 };
  }
}

export function convertStringToArray(value) {
  return Array.isArray(value) ? value : [value];
}

export const validURL = (str) => {
  if (typeof str !== 'string' || !str) {
    return false;
  }
  let url;

  try {
    url = new URL(str);
  } catch (e) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};

export default function colorPostTitleRealEstate({
  postType,
  isAuthorizedRe,
} = {}) {
  if (isAuthorizedRe) {
    return '#2459ad';
  }
  if (postType === 1 || isAuthorizedRe) {
    return '#FF0000';
  }
  if (postType === 2) {
    return '#EC6E00';
  }
  return '#000';
}

export function getArea(width, length, rear) {
  const intWidth = parseInt(width, 10),
    intLength = parseInt(length, 10),
    intRear = parseInt(rear, 10);
  if (intWidth <= 0 || intLength <= 0) {
    return '';
  }
  if (intRear > 0) {
    return ((intWidth + intRear) / 2) * intLength;
  }
  return intLength * intWidth;
}

export function cleanObj(obj) {
  for (let propName in obj) {
    if (typeof obj[propName] !== 'boolean') {
      if (obj[propName] === null) {
        delete obj[propName];
      }
      if (obj[propName] === undefined) {
        delete obj[propName];
      }
      if (obj[propName]?.length <= 0) {
        delete obj[propName];
      }
      if (typeof obj[propName] === 'object' && isEmpty(obj[propName])) {
        delete obj[propName];
      }
      if (
        typeof obj[propName] === 'number' &&
        (obj[propName] < 0 || isNaN(obj[propName]))
      ) {
        delete obj[propName];
      }
    }
  }
  return obj;
}

export const getMedia = (fileId) => {
  const apolloClient = initializeApollo();
  return apolloClient
    .query({
      query: READ_MEDIA_URLS,
      variables: { fileId },
    })
    .then(({ data }) => data.readMediaUrls);
};

export const getMedias = (fileIds) => {
  if (isEmpty(fileIds)) {
    return [];
  } else {
    return Promise.allSettled(fileIds?.map(getMedia)).then((result) =>
      result.filter((e) => e.status === 'fulfilled').map((e) => e?.value),
    );
  }
};

export const useTableData = (attachments = []) => {
  return attachments?.map((item) => ({
    ...item,
    id: item?.id,
    name: item?.loading ? '...' : item?.name,
    status: item?.loading
      ? `Đang tải lên ${parseInt(item.progress * 100, 10)}% ...`
      : item?.name
      ? 'Hoàn thành'
      : 'Lỗi tải lên',
  }));
};
export const useCopyText = async (value) => {
  const defaultToastOption = {
    position: isMobile ? 'top-center' : 'bottom-right',
    autoClose: 1500,
    closeButton: false,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
  navigator.clipboard.writeText(value).then(
    async () =>
      await toast(
        <MyToast type="success" message="Sao chép thành công" />,
        defaultToastOption,
      ),
    () =>
      window.flutter_inappwebview
        .callHandler('mobileCopyText', value)
        .then(
          () => async () =>
            await toast(
              <MyToast type="success" message="Sao chép thành công" />,
              defaultToastOption,
            ),
        ),
  );
};
