import styles from '@styles/components/Chat.module.css';
import MoreAction from '@components/Chat/components/MoreAction';
import ModalUserInfo from '@components/pages/MyNetwork/components/ModalUserInfo';
import React, { useState } from 'react';
import { useChatContext } from '@utils/context/chatContext';
import dynamic from 'next/dynamic';
import DrawerWrapper from '@components/DrawerWrapper';
const ModalWrapper = dynamic(() => import('@components/ModalWrapper'));
type MoreActionDrawerProps = {
  userId?: string,
  blockedBy?: any,
  myId?: string,
  isHide?: boolean,
  conversationId?: string,
};
export default function MoreActionDrawer({
  userId,
  blockedBy,
  myId,
  isHide = true,
  conversationId,
}: MoreActionDrawerProps) {
  const [showModal, setShowModal] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const {
    setShowRenameInput,
    setShowSearch,
    setConversationId,
    conversationId: conversationInner,
  } = useChatContext();
  return (
    <div className={styles.buttonMore}>
      <img
        onClick={() => setShowDrawer(true)}
        alt="more-action"
        src="/svg/3_dot.svg"
        className={styles.moreIcon}
      />
      {showDrawer && (
        <DrawerWrapper
          open={showDrawer}
          onClose={() => setShowDrawer(false)}
          container={process.browser && document.getElementById('modal-root')}
          className="w-full h-full">
          <div className={`w-full bg-white `}>
            <div
              className="w-full flex flex-col border-b pr-3 py-1 bg-white sticky top-0 h-50px"
              style={{
                borderColor: '#e2e2e2',
                borderTopLeftRadius: 16,
                borderTopRightRadius: 16,
              }}>
              <div className="flex justify-center items-center">
                <div className="w-32px h-4px rounded-full bg-gray-500" />
              </div>
              <div className="flex flex-1 items-center justify-center">
                <button
                  onClick={() => setShowDrawer(false)}
                  className="w-48px ">
                  <img alt="down" src="/svg/rectangleDownIcon.svg" />
                </button>
                <div className="flex flex-1 justify-center items-center px-2 text-base font-bold">
                  Thao tác khác
                </div>
                <div className="w-40px" />
              </div>
            </div>
            <div className="w-full h-200px overflow-y-scroll">
              <MoreAction
                context={{
                  isHide,
                  conversationId: conversationId || conversationInner,
                  setShowRenameInput,
                  setShowSearch,
                  userId,
                  setShowModal,
                  blockedBy: Array.isArray(blockedBy)
                    ? blockedBy?.includes(myId)
                    : blockedBy,
                  setConversationId,
                }}
                close={() => setShowDrawer(false)}
              />
            </div>
          </div>
        </DrawerWrapper>
      )}
      {showModal && (
        <ModalWrapper
          className="max-w-95% w-993px bg-white rounded-rd8px"
          open={showModal}
          onClose={() => setShowModal(false)}>
          <ModalUserInfo hideDeveloperName userId={userId} />
        </ModalWrapper>
      )}
    </div>
  );
}
