import { useGetMessages } from '@hooks/Chat/useGetMessage';
import Message from '@components/Chat/components/Message';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { InnerChatContext } from '@components/Chat/context';
import MessageLoading from '@components/Chat/components/MessageLoading';
import { useMutation } from '@apollo/client';
import { SEND_MESSAGE } from '@schemas/mutations/chat';
import useUnmountPromise from 'react-use/lib/useUnmountPromise';
import moment from 'moment/moment';
import { SpaceTime } from '@components/Chat/components/SpaceTime';
import { useChatContext } from '@utils/context/chatContext';
import { useSendMessage } from '@hooks/Chat/useSendMessage';
import useList from 'react-use/lib/useList';
import { reverse, uniqBy } from 'lodash';
// import { useIdleTimer } from 'react-idle-timer';

type Props = {
  conversationId?: string,
  participants?: Array,
  userData?: Object,
  myIdMobile?: string,
};

export default function ChatContent({
  conversationId,
  participants,
  userData,
  myIdMobile,
}: Props) {
  const { dataSearch, showDataSearch } = useChatContext();
  const { myId } = useContext(InnerChatContext);
  const commonId = myIdMobile || myId;
  const {
    data,
    loading,
    isLoadMore,
    loadMore,
    seenByByParticipants,
    hasNextPage,
  } = useGetMessages(conversationId, participants, commonId);

  const arrLoading = new Array(12).fill('loading');

  const scrollRef = useRef();
  const scrollOffset = useRef(0);

  const messageData =
    showDataSearch && dataSearch?.length > 0
      ? dataSearch
      : data?.messages?.items || [];

  const reverseMessage = useMemo(() => {
    return uniqBy(reverse([].concat(messageData)), (e) => e.id);
  }, [messageData]);

  const [sendMessage] = useMutation(SEND_MESSAGE),
    [isActive] = useState(true);
  const mounted = useUnmountPromise();
  const onMarkSeen = useCallback(async () => {
    try {
      await sendMessage({
        variables: {
          recipient: {
            conversationId: conversationId,
            recipientType: 'conversation',
          },
          senderAction: 'markSeen',
        },
      });
    } catch (e) {
      console.log(e);
    }
  }, [conversationId, sendMessage]);

  // useIdleTimer({
  //   timeout: 5000,
  //   debounce: 500,
  //   element: document.getElementById('container-box-chat'),
  //   onActive: () => {
  //     setIsActive(true);
  //   },
  //   onIdle: () => {
  //     setIsActive(false);
  //   },
  // });

  const lastMessage = reverseMessage[reverseMessage.length - 1];

  useEffect(() => {
    (async function () {
      if (isActive && lastMessage?.seenBy.find((e) => e !== commonId)) {
        await mounted(onMarkSeen());
      }
    })();
  }, [isActive, lastMessage, mounted, commonId, onMarkSeen]);

  useEffect(() => {
    setTimeout(() => {
      const chatBox = document.getElementById('chat-box');
      if (chatBox) {
        chatBox.scrollTop = chatBox?.scrollHeight;
      }
    }, [400]);
  }, []);

  const lastSeenMessageId = messageData.find((item) => {
    if (item.from === commonId && item.seenBy?.length === 2) {
      return true;
    }
  })?.id;
  const [attachments, setAttachments] = useList([]);
  const { onSendMessage } = useSendMessage({
    conversationId,
    setContent: () => {},
    attachments,
    setAttachments,
    inputRef: {},
    textAreaRef: {},
  });

  const onSayHi = useCallback(async () => {
    await onSendMessage({ nativeEvent: { text: '👋' } });
  }, [onSendMessage]);

  if (reverseMessage?.length === 0 && !loading) {
    return (
      <div className="w-full h-full flex flex-col flex-1 items-center justify-center space-y-4">
        <div className="text-base text-gray-300 text-center">
          Gửi lời chào đến {userData?.fullName}
        </div>
        <img alt="sayHi" src="/svg/sayHi.svg" className="w-64px h-64px" />
        <button
          onClick={onSayHi}
          className="text-sm w-60px h-40px rounded-rd8px bg-gray-300">
          Chào
        </button>
      </div>
    );
  }
  return (
    <div
      id="chat-box"
      ref={scrollRef}
      className="h-full flex-1 overflow-y-auto space-y-1 p-8px"
      onScroll={async (event) => {
        const { scrollTop } = event.nativeEvent.target;
        if (
          scrollTop < 20 &&
          scrollTop < scrollOffset.current &&
          hasNextPage &&
          !isLoadMore
        ) {
          await loadMore();
        }
        scrollOffset.current = scrollTop;
      }}>
      {(loading || isLoadMore) &&
        arrLoading.map((_, index) => {
          return (
            <MessageLoading isSend={index % 2 === 0} key={`loading-${index}`} />
          );
        })}
      {reverseMessage?.map((item, index) => {
        const isSend = item?.from === commonId,
          oneSeen = item.seenBy?.length === 1;
        const lastTime = moment(reverseMessage[index]?.createdAt),
          previousTime = moment(reverseMessage[index - 1]?.createdAt),
          duration = moment.duration(lastTime.diff(previousTime)),
          minutes = duration.asMinutes(),
          shouldShowSpaceTime = minutes > 15 && (
            <SpaceTime time={item?.createdAt} />
          );
        return (
          <React.Fragment key={index}>
            {shouldShowSpaceTime}
            <Message
              {...item}
              attachments={item.attachments}
              loading={item.loading}
              error={item.error}
              isSend={isSend}
              text={item.text}
              id={item.id}
              seenByByParticipants={seenByByParticipants}
              lastSeenMessageId={lastSeenMessageId}
              userData={userData}
              oneSeen={oneSeen}
            />
          </React.Fragment>
        );
      })}
    </div>
  );
}
