import { useMutation } from '@apollo/client';
import {
  BLOCK_CONVERSATION,
  PIN_CONVERSATION,
  REMOVE_CONVERSATION,
} from '@schemas/mutations/chat';
import React, { useCallback } from 'react';
import { resetQueries } from '@utils/helper';
import { toast } from 'react-toastify';
import MyToast from '@components/MyToast';
import { defaultToastOption } from '@utils/configs/Toast';

export const useFunctionChat = ({
  conversationId,
  setConversationId,
  close,
}) => {
  const [pinConversation, { client }] = useMutation(PIN_CONVERSATION);
  const [removeConversation] = useMutation(REMOVE_CONVERSATION);
  const [blockConversation] = useMutation(BLOCK_CONVERSATION);

  const onRemoveConversation = useCallback(async () => {
    try {
      close();
      await removeConversation({
        variables: {
          conversationId,
        },
      });
      resetQueries(['conversations'], client.cache);
      setConversationId && setConversationId('');
    } catch (e) {
      await toast(
        <MyToast type="error" message={e?.message} />,
        defaultToastOption,
      );
    }
  }, [conversationId]);
  const onBlockConversation = useCallback(async () => {
    try {
      close();
      await blockConversation({
        variables: {
          conversationId,
        },
      });
      resetQueries(['conversations', 'messages'], client.cache);
    } catch (e) {
      await toast(
        <MyToast type="error" message={e?.message} />,
        defaultToastOption,
      );
    }
  }, [conversationId]);

  const onPinConversation = useCallback(async () => {
    try {
      close();
      await pinConversation({
        variables: {
          conversationId,
        },
      });
      resetQueries(['conversations'], client.cache);
    } catch (e) {
      await toast(
        <MyToast type="error" message={e?.message} />,
        defaultToastOption,
      );
    }
  }, [conversationId]);

  return { onRemoveConversation, onBlockConversation, onPinConversation };
};
