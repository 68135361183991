import styles from '@styles/components/Chat.module.css';

export default function BrokerLoading() {
  return (
    <button className={`${styles.broker} animate-pulse`}>
      <div className="rounded-md w-full">
        <div className="animate-pulse flex space-x-2">
          <div className="rounded-full bg-blue-400 h-32px w-32px" />
          <div className="flex-1 space-y-1 py-1">
            <div className="h-4px bg-blue-400 rounded w-3/4" />
            <div className="h-4px bg-blue-400 rounded" />
            <div className="h-4px bg-blue-400 rounded w-5/6" />
          </div>
        </div>
      </div>
    </button>
  );
}
