import React from 'react';

export const ChatContext = React.createContext({
  conversationId: '',
  setConversationId: () => {},
  participants: '',
  setParticipants: () => {},
  showChat: false,
  setShowChat: () => {},
  showRenameInput: false,
  setShowRenameInput: () => {},
  showSearch: false,
  setShowSearch: () => {},
  dataSearch: null,
  setDataSearch: () => {},
  showChatMobile: false,
  setShowChatMobile: () => {},
  showInnerChatMobile: false,
  setShowInnerChatMobile: () => {},
  showDataSearch: false,
  setShowDataSearch: () => {},
});

export const useChatContext = () => React.useContext(ChatContext);
