import React, { useCallback, useEffect, useState } from 'react';
import MoreActionDrawer from '@components/ChatMobile/components/MoreActionDrawer';
import { useGetUserBasicInfo } from '@utils/hooks/Chat/useGetUserBasicInfo';
import { useChatContext } from '@utils/context/chatContext';
import { useGetConversation } from '@hooks/Chat/useGetConversation';
import EnterMessage from '@components/Chat/components/EnterMessage';
import ChatContent from '@components/Chat/components/ChatContent';
import { useSetNickname } from '@utils/hooks/Chat/useSetNickname';
import { useLazyQuery } from '@apollo/client';
import { SEARCH } from '@schemas/queries/chat';
import useDeepCompareEffect from 'react-use/lib/useDeepCompareEffect';

type RenderContentChatProps = {
  setShowChat?: Function,
  myId?: string,
  textAreaRef?: any,
  participants?: Array,
  conversationId?: string,
};
export default function RenderContentChat({
  setShowChat,
  myId,
  textAreaRef,
}: RenderContentChatProps) {
  const {
    participants,
    conversationId,
    setParticipants,
    showRenameInput,
    setShowRenameInput,
    setDataSearch,
    showSearch,
    dataSearch,
    setShowSearch,
    setShowDataSearch,
  } = useChatContext();

  const [searchString, setSearchString] = useState('');

  const userId = participants?.find((e) => e.userId !== myId)?.userId;
  const nickname = participants?.find(
    (e) => e.nickname !== null && e.userId !== myId,
  )?.nickname;
  const { data: dataBasic } = useGetUserBasicInfo({ userId });
  const data = dataBasic?.getUserBasicInfo || {};
  const tempName = nickname || data?.fullName;
  const [name, setName] = useState(tempName);
  const { data: conversationData } = useGetConversation({ search: '' });
  const listConservations = conversationData?.conversations.items || [];

  const isBlocked = !!listConservations?.find(
    (e) => e.id === conversationId && e.blockedBy?.includes(myId),
  );
  const [search, { loading: searchLoading, data: searchData }] = useLazyQuery(
    SEARCH,
    {
      fetchPolicy: 'no-cache',
    },
  );
  const onSearch = useCallback(async () => {
    try {
      await search({
        variables: {
          conversationId,
          keyword: searchString,
        },
      });
    } catch (e) {
      console.log(e);
    }
  }, [conversationId, search, searchString]);
  const { onSetNickName, loading } = useSetNickname({
    conversationId,
    name,
    setName,
    setParticipants,
    setShowRenameInput,
  });
  useEffect(() => {
    if (name === '' && tempName) {
      setName(tempName);
    }
  }, [tempName, setName, name]);
  useDeepCompareEffect(() => {
    if (searchData) {
      setDataSearch(searchData?.search);
    }
  }, [searchData, setDataSearch]);
  return (
    <div className="w-full h-screen bg-white">
      <div
        className="w-full flex flex-col justify-center border-b bg-white sticky top-0 h-50px"
        style={{
          borderColor: '#e2e2e2',
          zIndex: 10000,
        }}>
        <div className="flex flex-row items-center justify-between pr-4">
          <div className="w-full flex flex-row items-center space-x-2">
            <button onClick={() => setShowChat(false)} className="w-48px ">
              <img alt="back" src="/svg/back.svg" />
            </button>
            {showRenameInput ? (
              <div className="flex-1 flex flex-row justify-between space-x-2">
                <input
                  style={{ fontSize: 13 }}
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  className="flex-1 px-1.5 py-1 rounded-rd4px border"
                />
                <div className="flex flex-row items-center space-x-3 px-1">
                  {loading ? (
                    <div className="flex flex-row items-center justify-center space-x-1">
                      <div className="text-13 text-blue-main">Đang lưu</div>
                      <div className="animate-spin rounded-full h-3 w-3 border-t-2 border-b-2 border-blue-main" />
                    </div>
                  ) : (
                    <button
                      disabled={loading}
                      onClick={onSetNickName}
                      className="text-blue-main underline text-13 font-bold cursor-pointer">
                      Lưu
                    </button>
                  )}
                  <button
                    disabled={loading}
                    onClick={() => {
                      setShowRenameInput(false);
                      setName('');
                    }}
                    className="text-gray-500 text-sm font-bold cursor-pointer">
                    Hủy
                  </button>
                </div>
              </div>
            ) : (
              <p className="text-14 font-textBold font-bold">
                {nickname || data?.fullName}
              </p>
            )}
          </div>
          {!showRenameInput && (
            <MoreActionDrawer
              isHide={false}
              userId={userId}
              myId={myId}
              blockedBy={isBlocked}
            />
          )}
        </div>
      </div>
      {showSearch && (
        <div
          style={{ top: 50, zIndex: 10000 }}
          className="sticky flex flex-row justify-between space-x-2 bg-gray-200 p-1.5">
          {dataSearch && !searchLoading ? (
            <div
              onClick={() => setShowDataSearch(true)}
              className="text-blue-main text-sm font-bold underline cursor-pointer">
              Có {dataSearch?.length} kết quả tìm kiếm cho {`"${searchString}"`}
            </div>
          ) : (
            <input
              style={{ fontSize: 13 }}
              onChange={(e) => setSearchString(e.target.value)}
              value={searchString}
              className="flex-1 px-1.5 py-1 rounded-rd4px h-32px border"
              placeholder="Nhập để tìm kiếm"
            />
          )}
          <div className="flex flex-row items-center space-x-3 px-1">
            {!dataSearch && (
              <>
                {searchLoading ? (
                  <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-blue-main" />
                ) : (
                  <button
                    disabled={searchLoading}
                    onClick={onSearch}
                    className="cursor-pointer w-24px h-24px rounded-full">
                    <img
                      alt="go"
                      src="/images/go-back.png"
                      className="flex-1 transform rotate-180"
                    />
                  </button>
                )}
              </>
            )}
            <button
              disabled={searchLoading}
              onClick={() => {
                setShowSearch(false);
                setShowDataSearch(false);
                setSearchString('');
                setDataSearch(null);
              }}
              className={`text-sm font-bold cursor-pointer ${
                dataSearch && !searchLoading
                  ? 'text-blue-main underline'
                  : 'text-gray-500'
              }`}>
              {dataSearch && !searchLoading ? 'Xong' : 'Hủy'}
            </button>
          </div>
        </div>
      )}
      <div className="h-full flex-1 overflow-y-scroll">
        <ChatContent
          myIdMobile={myId}
          conversationId={conversationId}
          participants={participants}
          userData={data}
        />
        <div id="bottom_id" />
      </div>
      <div style={{ zIndex: 1000 }} className="sticky bottom-0 bg-white">
        <EnterMessage
          textAreaRef={textAreaRef}
          conversationId={conversationId}
        />
      </div>
    </div>
  );
}
