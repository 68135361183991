import React from 'react';
import moment from 'moment';
import { useIntervalForceUpdate } from '@utils/hooks/useIntervalForceUpdate';

type ActiveFunctionProps = {
  lastOnline?: string,
};
export default function ActiveFunction({ lastOnline }: ActiveFunctionProps) {
  const active =
    lastOnline &&
    moment.duration(moment().diff(moment(lastOnline))).asMinutes() < 5;
  useIntervalForceUpdate(10000);
  return (
    <div
      style={{
        position: 'absolute',
        zIndex: 1000,
        bottom: 0,
        left: 20,
        backgroundColor: active ? '#41CA54' : '#bcbcbc',
        width: 10,
        height: 10,
        borderRadius: '50%',
      }}
    />
  );
}
