import { useMemo } from 'react';
import { MultiAPILink } from '@habx/apollo-multi-endpoint-link';
import axios from 'axios';
import { getMainDefinition } from '@apollo/client/utilities';
import { buildAxiosFetch } from '@lifeomic/axios-fetch';
import { WebSocketLink } from '@apollo/client/link/ws';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from 'apollo-link-error';
import fetch from 'isomorphic-fetch';
import isEqual from 'lodash/isEqual';
import { setContext } from 'apollo-link-context';

import merge from 'deepmerge';

import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  createHttpLink,
  defaultDataIdFromObject,
} from '@apollo/client';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';
let apolloClient;

// const uat = 'https://uat-api.timnhachinhchu.com';
export const apiEndpoint = process.env.NEXT_PUBLIC_API_ENDPOINT;

const wsLink = (uri) =>
  new WebSocketLink({
    uri,
    options: {
      reconnect: true,
      connectionParams: {
        authToken: localStorage.getItem('silverBullet'),
      },
    },
  });

const authLink = setContext((_, { headers }) => {
  const token = process.browser && localStorage.getItem('silverBullet');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      introspectionToken: 'sometoken',
      ['accept-language']: 'vi',
      ['x-google-session-token']: sessionStorage.getItem('mapSessionToken'),
      ['x-app-introspect-auth']: 'gugotechcompany',
    },
  };
});

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, response }) => {
    if (
      graphQLErrors &&
      graphQLErrors.find((e) => e.extensions?.code === 'FORBIDDEN')
    ) {
      console.log(graphQLErrors);
      // onLogoutCallback().then();
    }
    if (
      graphQLErrors &&
      graphQLErrors.find((e) => e.extensions?.code === 'INVALID_PERMISSION') &&
      operation.query.definitions[0]?.operation === 'query' &&
      response
    ) {
      if (Object.entries(response.data)[0][1] === null) {
        // appStore.dispatch(push('/no-permission'));
      } else {
        response.errors = null;
      }
    }
    if (graphQLErrors) {
      console.log(graphQLErrors?.[0].message);
    }
    if (networkError) {
      console.log(networkError);
    }
  },
);

const multiLink = ApolloLink.from([
  new MultiAPILink({
    endpoints: {
      users: `${apiEndpoint}/users`,
      map: 'https://map.gugotech.com',
      image: `${apiEndpoint}/media`,
      realEstate: `${apiEndpoint}/realEstate`,
      ewallet: `${apiEndpoint}/ewallet`,
      common: `${apiEndpoint}/common`,
      chat: `${apiEndpoint}/chat`,
    },
    createWsLink: wsLink,
    createHttpLink,
  }),
]);

const httpLink = ApolloLink.from([errorLink, authLink, multiLink]);

const imageUploadLink = ApolloLink.from([
  errorLink,
  authLink,
  new createUploadLink({
    uri: `${apiEndpoint}/media/graphql`,
    fetch: buildAxiosFetch(axios, (config, input, init) => ({
      ...config,
      onUploadProgress: init.onUploadProgress,
    })),
  }),
]);

const isObject = (node) => typeof node === 'object' && node !== null;

// rough first draft, could probably be optimised in a loads of different ways.
const hasFiles = (node, found = []) => {
  Object.keys(node).forEach((key) => {
    if (!isObject(node[key]) || found.length > 0) {
      return;
    }

    if (
      (typeof File !== 'undefined' && node[key] instanceof File) ||
      (typeof Blob !== 'undefined' && node[key] instanceof Blob)
    ) {
      found.push(node[key]);
      return;
    }

    hasFiles(node[key], found);
  });

  return found.length > 0;
};

const finalHttpLink = ApolloLink.split(
  ({ variables }) => hasFiles(variables),
  imageUploadLink,
  httpLink,
);

const link =
  finalHttpLink ||
  ApolloLink.split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink(),
    finalHttpLink,
  );

function createApolloClient() {
  return new ApolloClient({
    link: process.browser ? link : multiLink,
    fetch,
    cache: new InMemoryCache({
      typePolicies: {
        Message: {
          fields: {
            loading: {
              read: () => {
                return false;
              },
            },
            error: {
              read: () => {
                return false;
              },
            },
          },
        },
      },
      possibleTypes: {
        MediaUnionWithFullUrls: ['FileWithFullUrls', 'YoutubeFile'],
        User: ['B2CUser', 'B2BUserType'],
      },
      dataIdFromObject(responseObject) {
        switch (responseObject.__typename) {
          case 'B2BUsers_PermissionOutputType':
            return `B2BUsers_PermissionOutputType:${
              responseObject.id
            }_${Math.random()}`;
          default:
            return defaultDataIdFromObject(responseObject);
        }
      },
    }),
  });
}

export function initializeApollo(initialState = null) {
  const _apolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s)),
        ),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function addApolloState(client, pageProps) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
}

export function useApollo(pageProps) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  return useMemo(() => initializeApollo(state), [state]);
}
