import { FILE_ALL_FIELDS } from '@schemas/fragments';
import gql from 'graphql-tag';

export const GET_DATASET_MY_NETWORK = gql`
  query getDatasetMyNetwork($province: ObjectID, $date: RangeDate)
  @api(name: users) {
    getDatasetMyNetwork(province: $province, date: $date) {
      n1 {
        date {
          day
          month
          year
        }
        total
      }
      n2 {
        date {
          day
          month
          year
        }
        total
      }
      n0 {
        date {
          day
          month
          year
        }
        total
      }
    }
  }
`;

export const GET_OVERVIEW_MY_NETWORK = gql`
  query getOverviewNetwork($date: RangeDate) @api(name: users) {
    getOverviewNetwork(date: $date) {
      provinceId
      provinceName
      totalN1
      totalN2
      totalN0
    }
  }
`;

export const GET_MEMBER_INFO_MY_NETWORK = gql`
  query getMemberInfo($id: ObjectID!) @api(name: users) {
    getMemberInfo(id: $id) {
      id
      type
      fullName
      memberId
      developer
      gender
      birthDate
      phones
      email
      province {
        id
        name
      }
      avatar {
        ...FileAllFields
      }
      taxId
      companyFields
      statusProfile
    }
  }
  ${FILE_ALL_FIELDS}
`;

export const LIST_COMPANY_BY_TYPE = gql`
  query listCompaniesByType(
    $searchString: String
    $province: String
    $district: String
    $type: CompanyEnum!
    $isPromoted: Boolean
    $sort: String
    $limit: Int
    $page: Int
    $companyFields: [String]
  ) @api(name: users) {
    listCompaniesByType(
      searchString: $searchString
      province: $province
      district: $district
      type: $type
      isPromoted: $isPromoted
      sort: $sort
      limit: $limit
      page: $page
      companyFields: $companyFields
    ) {
      total
      companies {
        id
        name
        logo {
          ...FileAllFields
        }
      }
      limit
      page
      nextPage
      prevPage
      totalPages
      pagingCounter
      hasNextPage
      hasPrevPage
    }
  }
  ${FILE_ALL_FIELDS}
`;
export const GET_REPRESENTATIVE_BROKERS = gql`
  query getRepresentativeBrokers($limit: Int) @api(name: users) {
    getRepresentativeBrokers(limit: $limit) {
      id
      memberId
      type
      fullName
      gender
      province {
        id
        name
      }
      avatar {
        ...FileAllFields
      }
      frequentREActivities
      frequentREProvinces
      phone
    }
  }
  ${FILE_ALL_FIELDS}
`;

export const GET_DEVELOPER_INFO = gql`
  query getDeveloperInfo($search: String!) @api(name: users) {
    getDeveloperInfo(search: $search) {
      id
      fullName
      phone
      memberId
    }
  }
`;

export const GET_USER_DEVELOPER_INFO_FOR_MY_NETWORK = gql`
  query getUserDeveloperInfo @api(name: users) {
    getUserDeveloperInfo {
      id
      fullName
      phone
      memberId
      email
      province {
        id
        name
      }
    }
  }
`;

export const GET_USER_INFO = gql`
  query getUserInfo @api(name: users) {
    user {
      type
      id
      fullName
      memberId
      company
      companyName
      memberId
      email
      taxId
      avatar {
        ...FileAllFields
      }
      idNumber
      province {
        id
        name
      }
      companyFields
      phones
      gender
      birthDate
      phoneChanged
      isPremiumAccount
      isShareInfo
      seenPremiumNoti
      developer
      developMember {
        id
        fullName
        phone
        memberId
      }
      role
      upgradePremiumRequest {
        status
        needUpdateFields {
          field
          reason
        }
      }
      isCompanyApproved
      companyRegisterRequest {
        id
        status
        creator
        rejectedReason
      }
    }
  }
  ${FILE_ALL_FIELDS}
`;

export const GET_USER_INFO_BY_FIELD = (field = []) => gql`
  query getUserInfo @api(name: users) {
    user {
      id
      fullName
      ${field.join()}
    }
  }
  ${field.find((e) => e.includes('FileAllFields')) ? FILE_ALL_FIELDS : ''}
`;

export const GET_USER_COLLECTION_BY_TYPE = gql`
  query getUserCollectionsByType(
    $type: CollectionEnum!
    $name: String
    $limit: Int
    $page: Int
  ) @api(name: realEstate) {
    getUserCollectionsByType(
      type: $type
      name: $name
      limit: $limit
      page: $page
    ) {
      total
      items {
        id
        type
        name
        totalItems
      }
      limit
      page
      nextPage
      prevPage
      totalPages
      pagingCounter
      hasNextPage
      hasPrevPage
    }
  }
`;

export const GET_LIST_BROKER = gql`
  query getListBroker(
    $search: String
    $province: String
    $district: String
    $realEstateType: String
    $demandType: String
    $sort: String
    $page: Int
    $limit: Int
  ) @api(name: users) {
    getListBroker(
      search: $search
      province: $province
      district: $district
      realEstateType: $realEstateType
      demandType: $demandType
      sort: $sort
      page: $page
      limit: $limit
    ) {
      total
      hasNextPage
      page
      users {
        id
        type
        fullName
        memberId
        province {
          id
          name
        }
        email
        phone
        avatar {
          ...FileAllFields
        }
        frequentREActivities
        gender
        birthDate
        zalo
        facebook
        skype
        viber
        isShareInfo
        description
      }
    }
  }
  ${FILE_ALL_FIELDS}
`;

export const GET_USER_BASIC_INFO = gql`
  query getUserBasicInfo($userId: String!) @api(name: users) {
    getUserBasicInfo(userId: $userId) {
      ... on B2CUser {
        id
        fullName
        avatar {
          ...FileAllFields
        }
        lastOnline
      }
      ... on B2BUserType {
        id
        fullName
        avatar {
          ...FileAllFields
        }
        lastOnline
      }
    }
  }
  ${FILE_ALL_FIELDS}
`;

export const COMPANY_INFO = gql`
  query companyInfo($id: String!) @api(name: users) {
    companyInfo(id: $id) {
      id
      name
    }
  }
`;

export const GET_ALL_REQUEST_OF_MEMBER = gql`
  query getAllRequestOfMember(
    $fullName: String
    $phone: String
    $province: ObjectID
    $statusProfile: [StatusProfileEnumType]
    $page: Int
    $limit: Int
    $sort: String
  ) @api(name: users) {
    getAllRequestOfMember(
      fullName: $fullName
      phone: $phone
      province: $province
      statusProfile: $statusProfile
      page: $page
      limit: $limit
      sort: $sort
    ) {
      total
      limit
      totalPages
      page
      pagingCounter
      hasPrevPage
      hasNextPage
      items {
        id
        type
        fullName
        avatar {
          ... on FileWithFullUrls {
            originalUrl
          }
        }
        phones
        province {
          id
          name
        }
        statusProfile
        createdAt
      }
    }
  }
`;

export const GET_DETAIL_USER_PROFILE = gql`
  query getDetailUserProfile($userId: ObjectID!, $tab: TabProfileEnumType!)
  @api(name: users) {
    getDetailUserProfile(userId: $userId, tab: $tab) {
      fullName
      frontIdImages
      backIdImages
      attachments
      companyLicense
      type
      email
      birthDate
      establishedDate
      phones
      company
      gender
      avatar
      idNumber
      taxId
      companyFields
      province {
        id
        name
      }
    }
  }
`;

export const GET_MEMBER_INFO = gql`
  query getMemberInfo($id: ObjectID!) @api(name: users) {
    getMemberInfo(id: $id) {
      id
      type
      fullName
      phones
      taxId
      companyFields
    }
  }
`;

export const MY_PROFILE_REQUEST = gql`
  query myProfileRequest($tab: TabProfileEnumType!) @api(name: users) {
    myProfileRequest(tab: $tab) {
      type
      fullName
      province {
        id
        name
      }
      birthDate
      establishedDate
      gender
      idNumber
      taxId
      company
      companyName
      companyFields
      frontIdImages
      backIdImages
      companyLicense
      description
      email
      avatar
      developer
      phones
      mediaOfCompany
      attachments
    }
  }
`;

export const LIST_BRANCHES_COMPANY = gql`
  query listBranchesOfCompany(
    $province: String
    $district: String
    $limit: Int
    $page: Int
  ) @api(name: users) {
    listBranchesOfCompany(
      province: $province
      district: $district
      limit: $limit
      page: $page
    ) {
      branches {
        id
        name
        phone
        address
        workTimes
      }
      limit
      page
      hasNextPage
      hasPrevPage
    }
  }
`;
export const GET_USER = gql`
  query userInfo($id: String!) @api(name: users) {
    userInfo(id: $id) {
      ... on B2CUser {
        fullName
      }
    }
  }
`;
