import styles from '@styles/components/Chat.module.css';
import Avatar from '@components/Menu/components/Avatar';
import { useMemo, useState } from 'react';
import IconCheck from '@public/svg/ic_check.svg';
import { useSpring, animated } from 'react-spring';
import RenderImage from '@components/Chat/components/RenderImage';
import { validURL } from '@utils/helper';
import PreviewLink from '@components/Chat/components/previewLink';
import Dropdown from '@components/Dropdown';
import MoreActionMessageDropdown from '@components/Chat/components/DeleteMessageDropdown';
import { Tooltip } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';
import moment from 'moment/moment';
import { makeStyles } from '@material-ui/core/styles';
import ModalViewMedia from '@components/modals/ModalViewMedia';
type Props = {
  isSend?: boolean,
  lastSeenMessageId?: boolean,
  text?: string,
  seenByByParticipants?: Array,
  id?: string,
  userData?: Object,
  loading?: boolean,
  oneSeen?: boolean,
  attachments?: Array,
  error?: boolean,
  createdAt?: any,
};

export default function Message({
  isSend,
  text,
  id,
  lastSeenMessageId,
  userData,
  loading,
  oneSeen,
  attachments,
  error,
  createdAt,
}: Props) {
  const [showModal, setShowModal] = useState(false);

  const timeTextFormat =
    moment.duration(moment().diff(moment(createdAt))).asDays() > 1
      ? 'DD-MM-YYYY hh:mm A'
      : 'HH:mm A';
  const [hover, setHover] = useState(false);
  const attachmentIds = attachments?.map((item) => ({
    fileId: item.attachmentId,
  }));

  const style = useSpring({
    backgroundColor: loading || error ? '#fff' : '#838b8b',
    border: loading || error ? `1px solid #838b8b` : undefined,
  });
  const renderStatus = useMemo(() => {
    if (lastSeenMessageId === id) {
      return (
        <Avatar
          avatarUrl={userData?.avatar?.originalUrl}
          wrapperClass="w-16px h-16px"
        />
      );
    }
    if (isSend && oneSeen) {
      return (
        <animated.div
          className="h-16px w-16px rounded-rd50 items-center justify-center flex flex-col"
          style={style}>
          <IconCheck
            fill={loading || error ? '#838b8b' : '#fff'}
            className="w-11px h-11px"
          />
        </animated.div>
      );
    }
  }, [userData, lastSeenMessageId, id, style, oneSeen, error]);

  const renderMessage = useMemo(() => {
    if (validURL(text)) {
      return (
        <div
          className={`${styles.message} ${
            isSend ? 'bg-blue-400' : 'bg-blue-main'
          }`}>
          <PreviewLink url={text} />
        </div>
      );
    }
    if (text?.length > 0) {
      return (
        <p
          className={`${styles.message} ${
            isSend ? 'bg-blue-main' : 'bg-blue-400'
          }`}>
          {text}
        </p>
      );
    }
    return null;
  }, [isSend, text]);
  const useStyles = makeStyles(() => ({
    customTooltip: {
      backgroundColor: 'rgba(220, 0, 78, 1)',
    },
    customArrow: {
      color: 'rgba(220, 0, 78, 0.9)',
    },
  }));
  const classes = useStyles();
  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={`w-full flex flex-row ${
        isSend ? 'justify-end' : 'justify-start'
      }`}>
      <div className="flex flex-row items-center space-x-1">
        {isSend && hover && (
          <div className="w-20px h-20px bg-gray-200 rounded-full p-1 cursor-pointer">
            <Dropdown
              dropdownContext={{ messageId: id }}
              DropdownComponent={MoreActionMessageDropdown}>
              <img
                alt="more"
                src="/svg/3_dot.svg"
                className="flex-1 transform rotate-90"
              />
            </Dropdown>
          </div>
        )}

        <div className={`flex ${isSend ? 'justify-end' : 'justify-start'}`}>
          <Tooltip
            classes={{
              tooltip: classes.customTooltip,
              arrow: classes.customArrow,
            }}
            PopperProps={{
              popperOptions: {
                modifiers: {
                  offset: {
                    enabled: true,
                    offset: '0px, -8px',
                  },
                },
              },
            }}
            placement="bottom"
            arrow
            TransitionComponent={Zoom}
            title={<b>{moment(createdAt).format(timeTextFormat)}</b>}>
            <div className="flex flex-col">
              {renderMessage}
              {attachmentIds?.length > 0 && (
                <RenderImage
                  onPress={() => setShowModal(true)}
                  files={attachmentIds}
                  isSend={isSend}
                />
              )}
            </div>
          </Tooltip>
          {showModal && (
            <ModalViewMedia
              listMedia={attachmentIds}
              open={showModal}
              onClose={() => setShowModal(false)}
            />
          )}
          <div className="mt-12px ml-4px w-16px">{renderStatus}</div>
        </div>
      </div>
    </div>
  );
}
