import MyQRCode from '@components/myQrCode';

type Props = {
  memberId?: string,
  size?: number,
};

export default function QrCode({ memberId, size = 146 }: Props) {
  return (
    <div className="flex flex-col w-full items-center">
      <MyQRCode value={memberId} size={size} level="H" />
    </div>
  );
}
